import React from "react";

// core components
import Navbar from "../components/Navbar.js";

// import MobileNavbar from "../components/Navbars/MobileNavbar";
import LandingPageHeader from "components/Headers/LandingPageHeader1.js";
import LandingPageHeaderMobile from "components/Headers/LandingPageHeaderMobile";
import TextGrid3 from "components/TextGrid3.js";
import DemoFooter from "../components/Footer.js";
import KeyTopics from "../components/KeyTopics.js";

import Agenda from "../components/Agenda.js";
import Reasons from "../components/Reasons.js";

import Testimonials from "../components/Testimonials.js";
import SpeakerCard from "../components/SpeakerCard.js";
import SponsorNew from "../components/SponsorNew";
import Sponsor from "../components/Sponsor";

import SaleHeader from '../components/Headers/SaleHeader.js';

import { news, newspeakers, pastsponsors, chairman, speakers } from "./content";

import News from "components/News.js";
import KeyNotes from "components/KeyNotes.js";

function Home() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      {/* <NavbarTop /> */}
      <Navbar />
      <section id="home" />
      <SaleHeader />
      {size ? <LandingPageHeader /> : <LandingPageHeaderMobile />}

      <TextGrid3 />
      <section id="about">
        <KeyTopics />
      </section>
      <section id="agenda">
        <Agenda />
      </section>
      {/* <section id="about">
          <TextGrid />
        </section> */}
      <KeyNotes />
      <section id="speakers">
        {/* <SpeakerCardOld speakers={speakers.sort((a, b) => a.name.localeCompare(b.name))} /> */}
        <SpeakerCard speakers={newspeakers} chairman={chairman} />
      </section>
      <Reasons />

      <section id="partners">
        <SponsorNew />
        <Sponsor sponsors={pastsponsors.sort((a, b) => a.name.localeCompare(b.name))} />
      </section>
      <Testimonials />
      <section id="news" />
      <News news={news} />
      {/* <section id="demand">
        <KeyNotes />
      </section> */}

      <DemoFooter />
    </>
  );
}

export default Home;
