import React from "react";
import { Container, Row, Col, Button } from "reactstrap";

import ScrollAnimation from "react-animate-on-scroll";

function Agenda() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
        }}
        className="section"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={12} className="text-center">
              <h2 className="text-700 text-center text-primary mb-5" text-center>
                WHAT WILL BE DISCUSSED
              </h2>
            </Col>
          </Row>
          <div className={`row justify-content-center ${size ? "row-cols-5" : "row-cols-2"}`}>
            {content.map((c, i) => (
              <Col className="mb-4">
                <ScrollAnimation animateIn={"fadeInUp"} animateOnce={true} duration={0.8}>
                  <div class="hex text-center">
                    <img
                      src={require(`assets/icons/${c.image}`)}
                      width="100%"
                      style={{ maxWidth: "100px" }}
                      alt="keypoint"
                    />
                  </div>

                  <p className="text-700 text-center text-primary mt-3">{c.title}</p>
                </ScrollAnimation>
              </Col>
            ))}
          </div>
        </Container>
        <Container fluid>
          <Row>
            <Col lg={12} className="pt-3 text-center">
              <Button
                href="/register"
                className="rounded-0 text-700 px-3 mr-2 border-0"
                style={{
                  background:
                    "#2b007b",
                }}
                size="lg"
              >
                <span
                  style={{
                    fontSize: "20px",
                  }}
                >
                  REGISTER FOR 2024
                </span>
              </Button>
            </Col>
            <Col lg={12} className="pt-3 text-center">
              <Button
                href="/sponsor"
                className="rounded-0 text-700 px-4 border-0"
                style={{
                  background:
                    "#2b007b",
                }}
                size="lg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span
                  style={{
                    fontSize: "20px",
                  }}
                >
                  BECOME A SPONSOR
                </span>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  { title: "Data Monetization", image: "Data Monetization.png" },
  // { title: "Advanced Analytics ", image: "Advanced Analytics.png" },
  { title: "Predictive Analytics", image: "Predictive Analytics.png" },
  // { title: "ChatGPT", image: "chatgpt-logo-02AFA704B5-seeklogo.com.png" },
  { title: "Data & AI Ethics", image: "Data & AI Ethics.png" },
  { title: "Data Governance", image: "Data Governance.png" },
  { title: "Real-Time Data Visualization", image: "Real-Time Data Visualization.png" },
  { title: "Data Modeling Languages", image: "Data Modeling Languages.png" },
  // { title: "Data Migration", image: "Data Migration.png" },
  // { title: "Real-Time Analytics", image: "Real-Time Analytics.png" },
  {
    title: "Internet of Things (IoT) and edge computing",
    image: "Internet of Things (IoT) and edge computing.png",
  },
  // { title: "Data Privacy", image: "Data Privacy.png" },
  // { title: "Data Mining", image: "Data Mining.png" },
  // { title: "Identifying Patterns in Data", image: "Identifying Patterns in Data.png" },
  // { title: "Data-driven Business Analytics", image: "Data Driven Business Analytics.png" },
  // { title: "Bringing Value with AI", image: "Bringing-Value-with-AI--.png" },
  // { title: "Data Management", image: "data-management.png" },
  { title: "Scaling with Data ", image: "Scaling with Data.png" },
  // { title: "Data Lakehouse & Warehouse", image: "data-warehouse.png" },
  // { title: "Data Strategies", image: "Data Strategies.png" },
];

export default Agenda;
// Data Monetization
// Advanced Analytics
// Predictive Analytics
// ChatGPT
// Data & AI Ethics
// Data Governance
// Real-Time Data Visualization
// Data Modeling Languages
// Data Migration
// Real-Time Analytics
// Internet of Things (IoT) and edge computing
// Data Privacy
// Data Mining
// Identifying Patterns in Data
// Data-driven Business Analytics
// Bringing Value with AI
// Data Management
// Scaling with Data
// Data Lakehouses & Warehouses
// Data Strategies
