import React from "react";
import { Navbar, Nav } from "reactstrap";
import Container from "reactstrap/lib/Container";

function NavbarDiv() {

  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;

  return (
    <>
      <Navbar
        style={{ height: size ? "45px" : "43px", backgroundColor: "#34ae7c" }}
        color-on-scroll='300'
        expand='lg'
        className={"fixed-top"}
      >
        <Container>
          {size && (
            <Nav className=' m-0'>
              {links.map((l) => (
                <a
                  className='mr-3 text-400 text-white pr-5'
                  style={{
                    backgroundColor: "transparent",
                  }}
                  href={l.link}
                  key={l.link}
                >
                  {l.title}
                </a>
              ))}
            </Nav>
          )}

          <Nav className=' m-0 ml-auto'>
            {social.map((s) => (
              <a
                key={s.name}
                className='mr-3'
                style={{
                  color: "#111111",
                  backgroundColor: "transparent",
                }}
                href={s.link}
              >
                <i
                  className={`fa fa-${s.name} text-white`}
                  style={{ fontSize: "1.5rem" }}
                />
              </a>
            ))}
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarDiv;

const social = [
  {
    name: "facebook",
    link: "https://www.facebook.com/martechvibe/",
  },
  { name: "twitter", link: "https://twitter.com/martechvibe" },
  {
    name: "linkedin",
    link: "https://www.linkedin.com/company/14603724/admin/",
  },
];

const links = [
  { title: "HOME", link: "/#" },
  { title: "OVERVIEW", link: "/#about" },
  { title: "AGENDA", link: "/#agenda" },
  { title: "SPEAKERS", link: "/#speakers" },
  { title: "PARTNERS", link: "/#partners" },
  { title: "NEWS", link: "/#news" },
  { title: "SPEAKER INSIGHTS", link: "/speaker-insights" },
];
