import React, { useState } from "react";
import { Button, Container, Row, Col, Input, Form, Spinner, Label } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import countries from "components/countries";

function Register({
  customer,
  setCustomer,
  onSubmitForm,
  loading,
  setLoading,
  setError,
  setPrice,
  details,
  setPromoApplied,
  setSuccess,
}) {
  const [errorObj, setErrorObj] = useState({});

  const onChangeDetails = (e) => {
    setErrorObj({});
    setCustomer({ ...customer, [e.target.name]: e.target.value });
  };

  const onChangeDetailsUdf = (e) => {
    setCustomer({ ...customer, udf: { ...customer.udf, [e.target.name]: e.target.value } });
  };

  const checkboxChange = (e) => {
    setCustomer({ ...customer, [e.target.name]: e.target.checked });
  };
  const [other, setOther] = React.useState(false);
  const onChangeIndustry = (e) => {
    const val = e.target.value;
    if (val === "Other") {
      setOther(true);
    } else {
      setOther(false);
      setCustomer({ ...customer, udf: { ...customer.udf, industry: e.target.value } });
    }
  };

  const validateInputs = () => {
    const reg =
      /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/;

    if (!customer.firstName) {
      setErrorObj({ ...errorObj, first_name: "Name is required." });
      return false;
    }
    if (!customer.lastName) {
      setErrorObj({ ...errorObj, last_name: "Name is required." });
      return false;
    }
    if (!customer.email || !reg.test(customer.email.toLowerCase())) {
      setErrorObj({ ...errorObj, email: "Enter Valid Email Address." });
      return false;
    }
    if (!customer.phone) {
      setErrorObj({ ...errorObj, phone: "Phone is required." });
      return false;
    }
    if (!customer.title) {
      setErrorObj({ ...errorObj, job: "Job Title is required." });
      return false;
    }
    if (!customer.company) {
      setErrorObj({ ...errorObj, company: "Company is required." });
      return false;
    }
    if (!customer.country) {
      setErrorObj({ ...errorObj, email: "Select a country from the dropdown." });
      return false;
    }
    if (!customer.udf.industry) {
      setErrorObj({ ...errorObj, email: "Industry is required" });
      return false;
    }

    return true;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setSuccess(false);

    const validateInp = validateInputs();
    if (!validateInp) {
      setLoading(false);
      setError("Please correct one or more fields above.");
      return null;
    }

    onSubmitForm();
  };

  let inputs = content;

  const ErrorComp = ({ error }) => (
    <span
      style={{
        background: "#ee1e23",
        color: "#fff",
        padding: "2px",
        marginTop: "5px",
      }}
    >
      {error}
    </span>
  );

  return (
    <>
      <Container className="my-auto text-400">
        <Form onSubmit={onSubmit} className="register-form text-white">
          <Row>
            {inputs.map((i) =>
              i.name !== "phone" ? (
                <Col lg={6} className="my-2" key={i.name}>
                  <label>{i.placeholder}*</label>
                  <Input
                    placeholder={i.placeholder}
                    type={i.type}
                    name={i.name}
                    onChange={onChangeDetails}
                    required
                  />
                  {errorObj[i.name] && <ErrorComp error={errorObj[i.name]} />}
                </Col>
              ) : (
                <Col lg={6} className="my-2">
                  <label>Phone*</label>
                  <PhoneInput
                    country={"ae"}
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    inputClass="w-100"
                    dropdownClass="text-dark"
                    value={customer.phone}
                    onChange={(phone, data) => {
                      setCustomer({
                        ...customer,
                        phone,
                        countryCode: data.dialCode,
                      });
                    }}
                  />
                  {errorObj.phone && <ErrorComp error={errorObj.phone} />}
                </Col>
              )
            )}
            <Col lg={6} className="my-2">
              <label>Country *</label>
              <Input
                placeholder="Select"
                type="select"
                name="country"
                onChange={onChangeDetails}
                required
              >
                <option value="">Select</option>
                {countries.map((c) => (
                  <option>{c}</option>
                ))}
              </Input>
              {errorObj.country && <ErrorComp error={errorObj.country} />}
            </Col>

            <Col lg={6} className="my-2">
              <label>Industry*</label>
              <Input
                placeholder="Select"
                type="select"
                name="industry"
                onChange={onChangeIndustry}
                required
              >
                <option value="">Select</option>
                <option>Airline</option>
                <option>Retail</option>
                <option>Real Estate</option>
                <option>Education</option>
                <option>Telecommunication</option>
                <option>Banking/Finance</option>
                <option>Tourism & Hospitality</option>
                <option>Consumer Electronics</option>
                <option>Media</option>
                <option>Entertainment</option>
                <option>Logistic & Supply Chain</option>
                <option>Other</option>
              </Input>
              {errorObj.industry && <ErrorComp error={errorObj.industry} />}
            </Col>
            {other && (
              <Col lg={12} className="my-2">
                <Input
                  type="text"
                  name="industry"
                  id="industry"
                  onChange={onChangeDetailsUdf}
                  placeholder="Industry"
                  required
                />
                {errorObj.industry && <ErrorComp error={errorObj.industry} />}
              </Col>
            )}
            <Col lg={12} className="my-2">
              <label>Interested in attending *</label>
              <Input
                placeholder="Select"
                type="select"
                name="interestedIn"
                onChange={onChangeDetailsUdf}
                required
              >
                <option value="">Select</option>
                <option value="SUMMIT">Summit</option>
                {/* <option value="SUMMIT_AND_WORKSHOP">Summit + Workshop (Paid)</option> */}
              </Input>
            </Col>

            {customer.udf && customer.udf.interestedIn === "SUMMIT_AND_WORKSHOP" && (
              <>
                <Col lg={12} className="my-2">
                  <label>No. of Delegate Attending*</label>
                  <Input
                    type="select"
                    name="delegateCount"
                    id="delegateCount"
                    value={customer.delegateCount}
                    onChange={(e) => {
                      const count = e.target.value;
                      setPrice(details.price * count);
                      setCustomer({
                        ...customer,
                        promo: undefined,
                        udf: { ...customer.udf, delegateCount: count },
                      });
                      setPromoApplied(false);
                    }}
                    placeholder="No. of Delegates Attending"
                  >
                    <option value={1}>1x Delegate Pass</option>
                    <option value={2}>2x Delegate Pass</option>
                    <option value={3}>3x Delegate Pass</option>
                    <option value={4}>4x Delegate Pass</option>
                  </Input>
                </Col>
                <Col lg={12} className="my-2">
                  <label>Billing Address*</label>
                  <Input
                    type="textarea"
                    required
                    name="billingAddress"
                    id="billingAddress"
                    onChange={onChangeDetailsUdf}
                    placeholder="Billing Address"
                  />
                </Col>
              </>
            )}

            <Col lg={12} className="my-2">
              <div className="ml-4">
                <Label check>
                  <Input
                    type="checkbox"
                    name="optIn"
                    onChange={checkboxChange}
                    checked={details.optIn}
                  />
                  Yes, by signing up, I agree to receive Vibe Media Group publications (
                  <a
                    href="https://datatechvibe.com/"
                    target="_blank"
                    rel="noopener"
                    className="text-success text-400"
                    data-saferedirecturl="https://www.google.com/url?q=https://datatechvibe.com/&amp;source=gmail&amp;ust=1654271285742000&amp;usg=AOvVaw1gkbAErbgV7Umk8_g06NxN"
                  >
                    Datatechvibe
                  </a>
                  ) newsletters and other marketing communications through email.&nbsp;
                </Label>
              </div>
            </Col>
            <Col lg={12} className="my-2">
              <div className="ml-4">
                <Label check>
                  <Input
                    type="checkbox"
                    name="privacyPolicy"
                    onChange={checkboxChange}
                    checked={details.privacyPolicy}
                  />{" "}
                  Yes, by signing up, I agree to have my contact information, including email,
                  passed on to the sponsors of this event for the purpose of following up on your
                  interests. Please read our{" "}
                  <a href="/privacy-policy" className="text-success text-400">
                    privacy policy,{" "}
                  </a>
                  <a href="/terms" className="text-success text-400">
                    terms and conditions
                  </a>
                </Label>
              </div>
            </Col>
          </Row>

          {loading ? (
            <span>
              <Spinner className="mt-3" color="warning" size="sm" />
            </span>
          ) : (
            <Button
              type="submit"
              disabled={loading}
              className="text-700 px-4 w-100"
              style={{ background: "#eb6229", border: 0 }}
              size="lg"
            >
              <span
                style={{
                  fontWeight: 700,
                  color: "#fff",
                }}
              >
                SUBMIT
              </span>
            </Button>
          )}
        </Form>
        <Row>
          <Col lg={12}>
            {/* <p className="text-700 mt-3 text-white">
              Please note: This event is exclusive only for delegates from the region and
              registration is subject to approval. Solution providers and consultants will not be
              accepted. The organising committee has the right to reject your registration if you
              are not deemed fit for the event and shall charge a 15% service fee upon cancellation
              and refunding of your registration.
              <br />
            </p> */}
            <p className="text-400 mt-3 text-white">
              {" "}
              Please note: Filling out the form does not guarantee admission to the event. This
              event is exclusive only for delegates from the region and registration is subject to
              approval. Solution providers and consultants will not be accepted.{" "}
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Register;

const content = [
  { id: 1, name: "firstName", type: "text", placeholder: "First Name" },
  { id: 1, name: "lastName", type: "text", placeholder: "Last Name" },
  { id: 3, name: "email", type: "text", placeholder: "Business Email" },
  { id: 4, name: "phone", type: "text", placeholder: "Mobile" },
  { id: 5, name: "company", type: "text", placeholder: "Company" },
  { id: 6, name: "title", type: "text", placeholder: "Job Title" },
];
