// reactstrap components
import { Container, Col, Row, Button } from "reactstrap";

import Typist from "react-typist";

import React from "react";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <div
        className="section"
        style={{
          backgroundColor: "#fff",
          backgroundImage: `url(${require("assets/images/bgphone1.png")})`,
          // backgroundAttachment: "absolute",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",

          backgroundSize: "100%",
          marginTop: "8rem",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={12} className="text-center">
              <h1
                className="header-text text-dark text-center mt-0"
                style={{
                  fontSize: "2.4rem",
                  letterSpacing: "-2px",
                }}
              >
                <Typist cursor={{ show: false }} avgTypingDelay={100}>
                  DATA IS
                  <br />
                  EVERYTHING
                </Typist>
              </h1>
            </Col>
            <Col lg={6}>
              <h3 className="text-700 text-dark text-center mt-0">WILL RETURN IN JUNE 2024</h3>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="pt-3 text-center">
              <Button
                href="/register"
                className="rounded-0 text-700 px-3 mb-2 border-0"
                style={{
                  background: "#2b007b",
                }}
                size="lg"
              >
                <span
                  style={{
                    fontSize: "20px",
                  }}
                >
                  REGISTER FOR 2024
                </span>
              </Button>{" "}
              <Button
                href="/sponsor"
                className="rounded-0 text-700 px-3 mb-2 border-0"
                style={{
                  background: "#2b007b",
                }}
                size="lg"
              >
                <span
                  style={{
                    fontSize: "20px",
                  }}
                >
                  BECOME A SPONSOR
                </span>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
