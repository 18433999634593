export const chairman = {};

export const newspeakers = [];

export const speakers = [
  {
    name: "Amith Rajan",
    title: "Executive Vice President",
    company: "Mashreq Bank, UAE",
    image: "Amith Rajan.jpg",
    text: [
      `
    As EVP, Head Data & Advanced Analytics, Amith leads the transformation of Mashreq Bank into a more Data-Driven organization. Straddling both business & technology, he manages the data estate for the Bank, its modernization, and governance while seeking opportunities to monetize this data through AI/ML and advanced analytics. Transforming into a data-driven culture is about changing mindsets, ways of thinking, and ingrained practices. It is an evolutionary process that impacts all aspects of an organization. Leading a One Data & Analytics team in the Bank is at the core of this transformation. A key focus for Amith is to break down data and domain silos within the Bank and create better customer experiences through analytics. 
    In a 20+ year career, Amith has moved seamlessly between business and technology, multiple times across the world. Prior to Mashreq Bank, Amith led data analytics and digital transformation for Standard Chartered Bank, Global Banking, in Singapore. 
    Amith is passionate about data-driven decision-making with contextual, intelligent analytics available at the right time across multiple channels and he looks to embed the data-driven culture everywhere he goes. Keynote speaker and active panelist for industry events, he enthusiastically mentors fintech startups and students in Singapore & Dubai and enjoys sharing his experience with others. Amith has successfully completed an Engineering from Pune University India, a PGDM from SCMHRD India and an MBA from University of Chicago Booth School of Business

    `,
    ],
  },
  {
    name: "Abhinav Kumar ",
    title: "Head of Data ",
    company: "Namshi.com, UAE",
    image: "Abhinav Picture.jpg",
    text: [
      `Abhinav has been leading data and analytics teams in MNCs across the globe, in Telecom, Fintech, Retail and Ecommerce sectors. He has expertise in turning data into value for the organization to drive revenue, optimize processes and reduce costs. He loves to uncover stories between customer behavior, process flows and business realities. Before leading teams, he has played multiple roles across the data spectrum wearing multiple hats as Data Engineer, Data Analyst and Data Scientist on different occasions. Abhinav has keen interest in anthropology and social science, and in his free time, he loves to read, travel, or watch movies.`,
    ],
  },
  {
    name: "Alexey Sidorov",
    link: "Alexey Sidorov",
    title: "Data Management Director & Chief Evangelist",
    company: "Middle East	Denodo",
    text: [
      `Dr Alexey was born with the “Passion for Data” programmed in his DNA.
    Alexey started his carrier 27-years ago building volumetric model of radioactive pollution of Chernobyl nuclear disaster Zone with Russian Academy of Science and since then earned his reputation as the strategic adviser in all aspects of Data Management.
    Prior joining Denodo as Chief Evangelist, Alexey built and managed enterprise-wide Data Integration platforms for industry giants like Nokia and Deutsche Bank. In the last 15 years Dr Alexey has been helping many companies across 3 continents to Digitally Transform their business with the edge-cutting Data technologies from Teradata and Informatica.
    `,
    ],
    image: "Denodo_Alexey Sidorov_Photo (1).jpg",
    company_image: undefined,
    topic: "",
  },
  {
    name: "Aamer Mushtaq",
    link: "Aamer Mushtaq",
    title: "Regional Manager Sales Engineering (Middle East, Turkey & Africa)",
    company: "Snowflake, UAE",
    text: [
      `Aamer Mushtaq -  leads the sales engineering team in the region and has been with Snowflake for over 4 years serving in multiple roles across Australia and Middle East. He helped build the Snowflake region in Australia and New Zealand from an early phase as one of the founding members of Snowflake team in downunder. Aamer has served customers in the Middle East region for over 10 years and is passionate about helping organisations on their data modernisation journeys.`,
    ],
    image: "Aamer_profile_pic (1).jpg",
    company_image: undefined,
    topic: "",
  },
  {
    name: "Ala Al Sharif",
    link: "Ala Al Sharif",
    title: "Senior Solutions Engineer Middle East",
    company: "Confluent, UAE",
    text: [
      `Senior Technology Specialist with 17 years of successful international experience in infrastructure and virtualisation consulting. Demonstrable experience in designing IT solutions that deliver business value. Proven track record of prestigious major client wins, most recently in cloud, virtualisation, mobility, and application delivery, working with partners and direct-touch on Enterprise accounts across the Middle East. Client sector experience includes Telecoms, Aviation, Finance, Retail, and Education. Accomplished presenter, with a mature, business-oriented approach to solution sales, working collaboratively with compatible vendors, colleagues, and partners. `,
    ],
    image: "Ala.jpg",
    company_image: undefined,
    topic: "",
  },
  {
    name: "Abhinav Mishra",
    link: "Abhinav Mishra",
    title: "Director Strategy",
    company: "Datatechvibe, UAE",
    text: [],
    image: "abhinav.jpg",
    company_image: undefined,
    topic: "",
  },

  {
    name: "Deepesh Jain ",
    title: "Director, Global Data Science ",
    company: "Visa, UAE",
    image: "Deepesh.jpg",
    text: [
      `Deepesh is a seasoned data science professional with 13 years of experience working in Banking,
    Financial Services and Telecommunications industries. He believes in the power of using data and
    leveraging data science to solve problems and add value to the business. He has extensive
    experience in credit cards, payments &amp; unsecured loan products and developing A/B/C &amp; fraud
    models, scorecards, strategies and policies using traditional and alternative data sources. His interest
    lies in interpretable machine learning.
    Deepesh holds a Master’s in Industrial Engineering &amp; Operations Research from IIT Bombay, India. In
    the past, he has worked with Citi, American Express, Genpact and DU.`,
    ],
  },
  {
    name: "Debbie Botha",
    link: "Debbie Botha",
    title: "Chief Partnership Officer",
    company: "Women in AI, UAE",
    text: [
      `Debbie has worked as a consultant and architect for 25 years on everything around what we call system of insights, data governance, data insights, data science and artificial intelligence today.  Her experience spans across 38 client engagements across 4 continents and 10 industries. She loves to help others grow in their technical careers by mentoring, teaching and speaking at Events, and developing communities like Women in AI.
    Debbie is passionate to have Partners as Fans of Women in AI, actively empowering the community of women from school to university and other education, to have opportunities for networking and getting their dream jobs in AI in Private and Public sector or even create their own Start-ups.
    `,
    ],
    image: "Debbie Botha (1).png",
    company_image: undefined,
    topic: "",
  },
  {
    name: "Emmet Kelly",
    title: "Chief Data Officer",
    company: "YAP Holding, UAE ",
    image: "Emmet.jpg",
    text: [
      `With 35 years’ experience working with Finance, Telecoms, Loyalty and FMCG data I've moved from Statistician to Digital Market Research entrepreneur, through to App Dev, Fraud, Regulatory, Compliance and Loyalty expert. Recently I’ve held director positions in Banking and Telecoms corporates in Ireland, the UK and the Middle East, (Meteor, Eircom/eir, AIB, Barclaycard and EITC/du).  Today I with Yap, in the MENAP region as Chief Data Officer. Launching in a host of countries with diverse cultures, languages, and legal and compliance rules I help guide this data-driven business toward ever more interesting and innovative solutions for our customers. We have huge populations to reach with exciting technology where we can educate, entertain, inform and empower those customers who need exciting life-changing financial tools the most.`,
    ],
  },
  {
    name: "Elvid Muslim",
    title: "Sr. Director of Data & Analytics",
    company: "DAMAC Properties Group, UAE",
    image: "Elvid new.jpg",
    text: [
      `Passionate Analytics professional with more than 13 years of experience in Data, Business Intelligence and Analytics. Successfully defined and implemented multiple data and analytics transformations within the different companies. Created Data, Business Intelligence and Analytics departments across the different industries by defining strategy, leading implementation, and instilling a data driven mindset. This includes design and development of different data platforms and data products (dashboards, data science models) with focus on improving internal organization capabilities while maximizing returns on the data investments.`,
    ],
  },
  {
    name: "Edosa Odaro",
    title: "Author of Making Data Work and Chief Data & Analytics Officer",
    company: "Tawuniya, Saudi Arabia",
    image: "Edosa given (1).jpg",
    text: [
      "Making Data Work: Enabling Digital Transformation, Empowering People and Advancing Organisational Success https://www.amazon.co.uk/dp",
      "/1032231920/",
      "ref=cm_sw_r_cp_api_i_BP1G52A6BWQQVJ8BTHSJ",
      "As a global cross-industry AI and data transformation leader, Edosa Odaro is the mastermind behind several innovations that have resulted in organisational success thanks to the effective implementation of data transformation strategies. In leadership positions across diverse sectors, he has helped over 35 leading international organisations deliver significant impact through analytics and intelligent interventions.",
      "As a Nigerian-born data specialist with an academic background in economics, computer science, business, technology and innovation, Edosa is a firm believer in the importance of a significant focus on people and on culture as well as a brand of inclusion that is underpinned by a diversity of minds. From his vast store of meticulous field notes and interview transcripts chronicling 23 years of experience, he has compiled a record of insights and methodologies never before presented.",
      "A keen advocate for diversity, a trusted adviser, an experienced presenter and a regular speaker, Edosa is a multi-award winner, including being named a Financial Times Top 100 Most Influential Leader, a Global 100 Data Activator, and one of the UK’s 30 Most Influential Black Leaders in FinTech & Data. ",
      "Beyond the world of work, Edosa enjoys spending time with his wife and two sons - experiencing a variety of activities, including football, cycling, running and skiing.",
    ],
  },
  {
    name: "Fawad Memon",
    title: "Director Advance Analytics and Insights",
    company: "Coca-Cola Export Corporation Dubai, UAE",
    image: "Fawad.jpg",
    text: [
      `Apart from the time where Fawad is not busy building broken cars he lives by a Latin Proverb “By learning you will teach and by teaching you will learn”. The inspiration from this proverb led Fawad to develop a career that has influenced him to keep evolving his learning process while also taking time out to teach. Fawad has worked for some of the most recognizable companies in the world like
    P&G and Coca-Cola and in the process has worked on insight generation, strategic
    planning, and business transformation. In his recent role he has been leading the in-house Data Analytics and Digitization agenda for the Eurasia Middle East Operating Unit. For him
    the success in this role is dependent on accurately creating, predicting, and fulfilling
    consumer demand despite volatile and uncertain operating environment. On the teaching front, Fawad has had the pleasure of teaching Marketing Research across various geographies including USA, Pakistan, and Dubai. Inspired by the Video Conferencing Era post COVID Fawad is now evaluating a path that can help him earn a PhD.
    `,
    ],
  },
  {
    name: "Hafdi Salah",
    link: "Hafdi Salah",
    title: "Cofounder and Managing Partner",
    company: "BBI Consultancy",
    text: [
      `Hafdi has more than 24 years' experience in technology solutions, consulting and
  delivery at executive level, with an outstanding track record of strategic
  development of new visions tomeet the long-term business requirements in
  dynamic environment.`,
      `
  Mr. Salah lead many mega-scale, end-to-end enterprise data management project
  in many sectors including telco, banking and government.`,
      `
  Before co-founding BBI, Mr. Salah held many technical and managerial positions
  with leading multinationals like Du and Atos.`,
      `
  Hafdi is a computer science graduate from the AUC, and holds a Masters of
  Technology from the University of California, Berkeley.`,
    ],
    image: "hafdi.jpg",
    company_image: undefined,
    topic: "",
  },
  // {
  //   name: 'Gnaneesh Reddy',
  //   title: 'Chief Technology Officer',
  //   company: 'Al Safeer Group, UAE',
  //   image: 'Gnaneesh.jpg',
  //   text: [],
  // },
  // {
  //   name: 'Hamzeh Hamarneh',
  //   title: ' Head of Data Analytics',
  //   company: 'Dubai Digital Authority, UAE',
  //   image: 'placeholder.png',
  //   text: [],
  // },
  {
    name: " James Schlesinger",
    title: "Senior Product Manager, Web Analytics",
    company: "Chalhoub Group, UAE",
    image: "james_schlesinger (1).jpg",
    text: [
      `Following my early experience, as an IT Technician and whilst still a qualified network engineer, I transitioned into Digital Marketing a decade ago. Most recently I could be found within the Global Media Tech team at Dyson HQ (UK), navigating a heavily process-driven Group Commercial team, as well as supporting market teams across the globe. Strong relationships built there provided me with a new opportunity in Dubai at Chalhoub Group, where I re-joined former Dyson colleagues.`,
      `Luxury Retail is our primary focus at Chalhoub, with a diverse portfolio of brands, ensuring Group-wide projects receive stakeholder buy-in and deliver results is a challenging task. Our key successes to date include enabling an Identity Resolution platform and adopting a Data Activation tool to power server-side event tracking and audience segmentation. We have also implemented a bleeding edge Continuous Design Platform tool.`,
      `Building out a team in a new region has been a rewarding part of the journey. Seeing the evolution of Paid Media over recent years has meant leaning more on my IT skills, as the technical side of marketing​ has forced my career to go full circle as I upskill in Cloud Technologies.`,
    ],
  },
  {
    name: "Keeshan Pillay",
    title: "Enterprise lead for MEA",
    company: "Dremio",
    image: "Keeshan.jpg",
    text: [],
  },

  // {
  //   name: ' Khaled Abou Samak',
  //   title: 'Head of Data Science and Analytics',
  //   company: 'Abu Dhabi Chamber of Commerce and Industry, UAE',
  //   image: 'khaled.jpg',
  //   text: [
  //     `Khaled is one of the top data science, AI & machine learning, Data Governance, and Data Analytics experts in the UAE market. He has over 20 years of corporate experience in solving real-world business problems by translating them into AI and Data Science problems.`,
  //     `He has worked for blue-chip companies across different domains, including Microsoft, General Motors, Alfahim, and governmental agencies in UAE such as Musanada and Abu Dhabi Chamber.`,
  //     `He builds AI and digital strategies and develops roadmaps to create a competitive advantage based on Machine Learning solutions. He also helps organizations to transform their management practices in digital business domains and improve their business performance by utilizing value from data. Besides that, he develops data governance strategies, data architecture, policies, and roadmaps to ensure data is managed as an asset across the organization.`,
  //     `He helps organizations to transform their management practices in digital business domains and improve their business performance by utilizing value from data.`,
  //   ],
  // },
  {
    name: "Latifa AlShehhi ",
    title: "Head of Data Management ",
    company: "Road and transportation Authority Dubai, UAE",
    image: "Latifa.jpg",
    text: [
      `An Emirati data enthusiast leader in the field of data tech, digital transformation, innovation and well-known in the region; since 2016 leading major project to support transforming the UAE data and statistics landscape to become the best country in the world in smart data. She is leading high-impact projects in the data realm to support the UAE digital growth and improve data literacy in the region. She believes that the Public-Private People Partnership is at the core of making the Dubai a leading city by leveraging the power of data. `,
      `Latifa is currently the Head of Data Management at the Road and Transport Authority in Dubai, As part of her responsibilities and duties, she is developing strategies in enhancing data culture and literacy in the authority including data governance programs and AI ethics and enhancing using emerging data sources, and most recent data technologies in smart cities.`,
      `Prior to that she was the Head of Algorithms Development and Analysis at the Federal Competitiveness and Statistics Center in the Ministry of Cabinet Affairs; in charge of Digital transformation of the Statistical business. She was worked on the establishment of the UN Global Platform for Big Data and Data Science in the MENA region in collaboration with the private sector in addition, enabling the Data science community in the arab world. Her portfolio of work Also encompassed the UAE open data portal” bayanat.ae”, enabling statistical data exchange “Emirates Data Network” and the Enabler platform a one stop shop of analytics and information for decision-makers. `,
      `She is also a member in various international groups and the youngest member in the United Nations committee of Experts in Big data and Data Science , member of the UAE Advisory Committee of the Hope, also member of the Open Data Institute and the UAE representative in the ISI committee for women in statistics.`,
      `Latifa also holds a Master’s Degree in Business Management from the Canadian University. Bachelor in Business from Zayed University Dubai. She also graduated from the UAE Government Youth Leadership Program and has completed many international and local courses such as Governance of Artificial Intelligence program from Mohammed Bin Rashid School of Government.`,
    ],
  },
  // {
  //   name: 'Mohamed Zouari',
  //   title: 'GM META',
  //   company: 'Snowflake, UAE',
  //   image: 'Mohamed Zouari.jpg',
  //   text: [
  //     'Mohamed ZOUARI has been in the Software Industry for 20 years. After 10 years of experience with the Software division of Hewlett Packard Enterprise as Strategic Account Manager, he joined the Snowflake adventure in 2018. He is now General Manager for the Middle East Turkey and Africa region collaborating with local Partners and Customers in their data driven transformation journey using the best Cloud Data Platform in the Market.',
  //     'Mohamed brought the hottest Software company into the UAE and opened a legal entity (Snowflake Middle East FZ LLC), an office in Dubai Internet City, hired a local team and successfully launched Snowflake on Azure UAE in collaboration with Microsoft in 2022.',
  //     'He is working with several government entities like Dubai Digital Authority to make the UAE the most advanced country when it comes to Data & AI.',
  //   ],
  // },
  {
    name: "Peter Riz",
    title: "Chief Information Officer",
    company: "OSN, UAE ",
    image: "peter.jpg",
    text: [
      `
    Peter Riz has over 20 years of premium Pay Television and OTT experience within the Middle East and Europe, with a focus in Digital Transformation. 
    `,
      `During his carrier Riz has different positions in content operation, project management, technical operation, product development and engineering roles. In the last 10 years, Riz assumed various technical leadership and strategic responsibilities specifically focusing on the development and operations of over-the-top/streaming media products.
`,
      `
Today Riz takes the role of Chief Technology Officer at OSN, where he leads both the broadcasting and streaming technology strategy and execution. Prior to this, Riz was Chief Technology Officer at Intigral in the Middle East, where he played a key role of the development and operation of Intigral cloud-based high-quality OTT streaming service Jawwy TV. Riz also spent over 12 years with HBO Europe where he was the Senior Vice President – Technology. Under his leadership at HBO he was responsible for all aspects of Product Design, Development, Delivery and Operation of HBO Europe OTT platform branded as HBO GO.

    `,
    ],
  },
  {
    name: "Prashant Tewari",
    title: "Regional Sales Director - Middle East Turkey Africa (Meta) ",
    company: "Acceldata Middle East",
    image: "prashant.jpg",
    text: [
      `Prashant is an accomplished leader in the domain of Data & Analytics. Has over 24 years of experience with technology leaders in the Middle East and South Asia. Currently leads the Acceldata business in the Middle East, Turkey, Africa region. Leads discussions with the CXO's in the region for Data Observability in the realms of Big Data Compute and Data Quality.  
    `,
    ],
  },

  {
    name: "Rebecca McLaughlin-Eastham",
    link: "Rebecca McLaughlin-Eastham",
    title: "",
    company: "TV Presenter, Event Host & Moderator",
    text: [
      `Based in Dubai, Rebecca is a dynamic event host, TV anchor, business and live news correspondent. `,
      `She has shone on the global stage for more than 20 years, having broadcast for leading U.S, European and Middle East television networks, including Bloomberg, CNBC, CBC, Euronews and Dubai One. `,
      `Rebecca has interviewed thousands of CEOs, ministers, heads of state and celebrities over the years, and has regularly contributed to publications from The National newspaper and Wall Street Journal to Vogue Magazine and the South China Morning Post. `,
      `She constantly moderates and hosts high-profile economic, business, political and cultural events, forums and summits around the world, and is a sought-after executive media trainer. `,
      `Rebecca is a globetrotting Irish national whose family has proudly called the UAE home for more than 25 years. She is a university graduate of the London School of Economics & Political Science.`,
    ],
    image: "Rebecca (1).jpg",
    company_image: undefined,
    topic: "",
  },
  // {
  //   name: 'Rinesh Patel',
  //   title: 'Global Head of Industry, Financial Services',
  //   company: 'Snowflake, UAE',
  //   image: 'placeholder.png',
  //   text: [``],
  // },
  {
    name: "Surajit Basak",
    title: "Sr. Director of Information Technology",
    company: "UPS",
    image: "Surajith.jpg",
    text: [
      "He is an award-winning senior executive and global leader leading and directing mission critical business initiatives for high paced multi-national organisations. He has been performing strategic transformation and customer engagement roles over 23+ years for Fortune 50, 100 & 500 financial organisations like ING, Goldman Sachs; Manufacturing organisations like Avery Dennison, and supply chain & logistics organisations like United Parcel Services.",
      "He has lead a number of large sized multi-cultural teams through Enterprise Resource Planning, Enterprise Architecture, DevSecOps, Business Intelligence and Master Data Management implementations providing Diagnostic, Predictive and Perspective Analytics capabilities using Machine Learning, Artificial Intelligence and Data Lake. He has designed and built Enterprise Architecture, which is scalable and robust with Information Security and that gives value to business by providing fast return on investments.",
      "He has lead digital transformations in an Agile way (Safe, Scrum, Kanban, Disciplined Agile) and made the stakeholders happy through proper organisation and change management. ",
      "He has mentored and coached many senior managers and/or aspiring technologists and data scientists through their personal and professional success. He is a fun-loving person, who always takes new challenges with a smile and aims to give back to society.",
    ],
  },
  {
    name: "Sid Bhatia",
    title: "RVP and General Manager",
    company: "Dataiku Middle East ",
    image: "Sid Bhatia.jpg",
    text: [
      `Sid is with Dataiku as the Regional Vice President & General Manager for the Middle East & Turkey, he is working strategically with the top 20% of the best known Data-driven clients in the region that are committed to gathering data concerning all aspects of the business as information-based decision-making becomes part of their competitive advantage.  Sid brings many years’ worth of Leadership, Management & Sales experience to his role, having worked with companies like Cloudera, IBM and Sybase (SAP) exclusively focused on Information Management & Analytics Space. With experience at different levels - start-up, growth stage and established organizations, Sid joined Dataiku as the first person to set up operations for Dataiku & now heads a rapidly expanding team to support Dataiku's growth and support its key customers on their path to Enterprise AI.  Dataiku is the world’s leading platform for Everyday AI, systemizing the use of data for exceptional business results. 
      By making the use of data and AI an everyday behaviour, Dataiku unlocks the creativity within individual employees to power collective success at companies of all sizes and across all industries. `,
    ],
  },
  {
    name: "Tomasz Mazur",
    title: "Head of Customer Acquisition",
    company: "Chalhoub Group, UAE",
    image: "Tomasz.jpg",
    text: [
      `Today I'm privileged to be directing the Chalhoub Group Web Analytics team, providing expertise on everything from data collection to analysis and activation. A strong strategist, I am excited to keep pushing the cutting edge of web analytics.`,
      `My digital career kickstarted in Berlin, working in analysis and honing my skills in A/B testing for retail giant Zalando. An aptitude for conversions and optimization allowed me to pursue this through consultancy, working with both large companies and start ups.`,
      `A fantastic opportunity in digital analytics then took me to Dubai. Here I was able to sharpen my technical skills whilst taking on the challenge of a brand new region. During this time, implementation and advanced web analytics organically progressed into data collection, data warehousing and more 'data strategy'.`,
    ],
  },

  {
    name: "Waseem Akram Syed",
    title: "Director Data (Analytics)",
    company: "Kitopi, UAE",
    image: "waseem.jpg",
    text: [
      `Waseem is a Director of Data at Kitopi - the world’s leading managed cloud kitchen platform. Waseem leads the data efforts at kitopi which include BI, Analytics, Data Engineering, ML Ops and Data governance. Besides data, Waseem has a passion for automation and helps organizations solve manual processes both at the back office and in business operations using Robotic Process Automation. Prior to Kitopi, Waseem helped companies (across banking and consumer) be more data-driven in the way they conduct their business. Introducing data culture across business teams is a disruptive process and Waseem brings in expertise setting up and driving this uncomfortable process. Driving data culture starts from building a data team from the scratch, empowering the team with the right training and analytical ownership, embedding the data at various touchpoints of the business processes and driving the data backed decision management. 
      Besides, Waseem builds the data strategy for the organization to have a long-term data vision and build scalable and sustainable data platforms
      `,
    ],
  },

  {
    name: "Zee Hussain",
    title: "Enterprise Sales Leader for UAE",
    company: "Qlik, UAE",
    image: "Zee husain.jpg",
    text: [
      "As a keen Formula 1 enthusiast and adrenaline seeker, when not on the racetrack Zee is responsible for leading Data Analytics & Data Integration across the UAE region at Qlik, helping enterprises move faster, work smarter and lead the way forward with an end-to-end data platform. ",
      "Qlik is trusted by hundreds of enterprises across the Middle-East region, recognized by Gartner as a leader for 12 consecutive years and also has the highest annual growth in the data integration sector. Zee is primarily leading the engagement and advising enterprise organizations on their data analytics strategy that leverages Qlik’s unique Active Intelligence Platform.  ",
      "Prior to joining Qlik, Zee has over 15 years of experience in the technology industry at some of the world’s leading technology organizations including Microsoft, Salesforce, Gartner and Oracle  - covering the European and Middle-East markets.",
    ],
  },

  {
    name: "Janne Liuttu",
    link: "Janne-Liuttu",
    title: "Chief Data Officer",
    company: "Ramboll ",
    text: [
      `Janne is a visionary leader specialized in developing data-driven organizations and strategies, with hands-on expertise in advanced analytics. As Chief Data Scientist, he is leading global analytics & data science practice at Ramboll, creating sustainable solutions across buildings, transport, water,
    energy, environment & health and architecture, landscape &urbanism. Before joining Ramboll. Janne has been working in strategic data initiatives for both startups and multinational corporations in various industries. Janne holds MSc in mathematics from Tampere University of Technology and MBA from Henley Business School.`,
    ],
    image: "Janne.jpg",
    company_image: undefined,
    topic: "",
  },
  // {
  //   name: "Hesham Al Ghamdi",
  //   link: "Hesham-Al-Ghamdi",
  //   title: "Chief Data & Analytics Officer",
  //   company: "Abdul Latif Jameel",
  //   text: [],
  //   image: "Hesham.jpg",
  //   company_image: undefined,
  //   topic: "",
  // },
  {
    name: "Mohammed Jamal",
    link: "Mohammed-Jamal",
    title: "Head of Data Science",
    company: "Aramex",
    text: [],
    image: "Jamal.jpg",
    company_image: undefined,
    topic: "",
  },
  {
    name: "Gino George",
    link: "Gino-George",
    title: "Head of Analytics & AI",
    company: "First Abu Dhabi Bank",
    text: [],
    image: "placeholder.png",
    company_image: undefined,
    topic: "",
  },
  // {
  //   name: "Dr. Rafiq Jamalaldeen",
  //   link: "Rafiq-Jamalaldeen",
  //   title: "Director of Data & Advanced Analytics",
  //   company: "Saudi Arabian Monetary Authority (SAMA)",
  //   text: [
  //     `Experienced Director Information Technology Infrastructure with a demonstrated history of working in the financial services industry. Skilled in Data Center, Disaster Recovery, Process Engineering, Information Technology, and ITIL. Strong information technology professional with a PhD focused in Computer Engineering, Computer Networks from King Saud University, PhD.`,
  //   ],
  //   image: "Rafiq.png",
  //   company_image: undefined,
  //   topic: "",
  // },
  {
    name: "Maciej Kaliszka",
    link: "Maciej Kaliszka",
    title: "Chief Data Officer",
    company: "Absa Group",
    text: [
      `Having gained extensive experience as a Strategy Management Consultant, Maciej entered the banking industry with a passion to deliver data as an asset and catalyst to business growth and optimisation. He recently added a post graduate qualification in Artificial Intelligence, Machine Learning and Data Science to his education and uses his broad business and technical expertise to galvanise insights into action. This led to Maciej being nominated in 2020 as one of the Global Top 100 Innovators in Data and Analytics.`,
    ],
    image: "Maciej.jpg",
    company_image: undefined,
    topic: "",
  },
  {
    name: "Sara Al Zarooni",
    link: "Sara-Al-Zarooni",
    title: "Director, Data Management & Compliance Affairs",
    company: "Dubai Data Establishment Smart Dubai",
    text: [
      `Sara Al Zarooni is a well-known leader in the fields of smart cities
      and digital transformation. Currently the Director of Data
      Management and Compliance Aairs at the Dubai Data
      Establishment, the entity leading Smart Dubai’s data eorts, Al
      Zarooni has been leading the Dubai Data Initiative since 2017.
      Collaborating with over 350 Data Champions from the government
      and private sectors, Al Zarooni has played a role in the launch of
      Dubai Pulse, the city’s digital backbone which hosts over 700
      datasets today.
      Prior to her role at Smart Dubai, Al Zarooni was the Manager for the
      IT Project Management Oce at the Dubai Electricity and Water
      Authority. Al Zarooni holds a B.Sc in Management Information
      Systems from the University of Sharjah.
      In addition to her day job, Al Zarooni is also a member of the Dubai
      Leaders Program launched by The Executive Council of Dubai to
      train, upskill and prepare the next generation of Dubai Government
      leaders.`,
    ],
    image: "sara.jpg",
    company_image: undefined,
    topic: "",
  },
  {
    name: "Ibrahim Al Najjar",
    link: "Ibrahim-Al-Najjar",
    title: "Director Information Technology",
    company: "DP World",
    text: [
      `Ibrahim Al Najjar is leading the Information Technology department of DP World, UAE Region where innovative solutions and IT service offerings are provided to a wide range of business units that cover shipping, transport, and logistics industries along with integrations across the supply chain entities. The IT department of DP World is a pioneer in business enablement and enrichment through technology and continuously explores, design and implement the latest disruptive innovations.`,
    ],
    image: "ibrahim.jpg",
    company_image: undefined,
    topic: "",
  },

  {
    name: "Vivek Sahabadi",
    link: "Vivek Sahabadi",
    title: "Group Head Data & Analytics",
    company: "Apparel Group, UAE",
    text: [
      `Vivek is a dynamic analytics leader who has worked with modern companies and startups. He has helped the companies and BU to be data-driven and has built the data Culture by leading the department as a whole.`,
      `He has built/modernize the complete data infrastructure and also build the team from scratch and upskilled the existing resources. He has worked on problems related to supply chain analytics, marketing analytics, product analytics, category&merchandising analytics, fraud analytics, etc.`,
      `He has worked in companies like Flipkart, OLX,OVO(Indonesia's biggest fintech company), Amazon(souq.com middle east), and Namshi(middle east biggest fashion retailer) where he was responsible at different capacities for complete data-driven decision making(including automated models ). Vivek is a start-up person at heart and loves to drive data culture in start-ups from conceptualizing to final delivery and maintenance of the solution`,
    ],
    image: "vivek.jpg",
    company_image: undefined,
    topic: "",
  },
  {
    name: "Joseph George",
    link: "Joseph-George",
    title: "Chief Information Officer",
    company: "National Bank of Fujairah",
    text: [
      `Dr. Joseph George joined National Bank of Fujairah in May 2015 as CIO - Head of Information Systems and Technology. He brings to his role a wealth of Banking domain knowledge and technology expertise that spans over two decades. 
<br/>
    In his current role at NBF, Dr George focuses on ensuring a stable, resilient and scalable technology platform that supports the Bank’s strategic, operational and security goals. His in-depth knowledge of Banking Processes coupled with his technology expertise has enabled him to usher in a digital revolution at NBF that saw many operational processes re-engineered, digitized and automated and several digital services being rolled out to the customers, resulting in a seamless customer experience.
    <br/>
    Prior to joining NBF, Dr George served in various senior roles in the Banking and Financial industries including his previous role as Head of IT Development at Commercial Bank of Dubai where he oversaw the implementation of several digital services such as the launch of first Facebook branch, intelligent virtual assistant, the first analytics and customer onboarding in the middle east region etc…
    <br/>
    Dr. Joseph George holds a Bachelor’s degree in Computer Science, Diploma in Cyber Law, Master’s degree in Computer management, MSc in psychology, M. Phil with specialization in Software Project Management and subsequently did his Doctorate in Software Project Management from the University of Pune. He also recently earned a post graduate certificate in Artificial Intelligence and Machine Learning from Texas Tech University, Austin.
    `,
    ],
    image: "joseph.jpg",
    company_image: undefined,
    topic: "",
  },
  {
    name: "Ravi Raman",
    link: "Ravi Raman",
    title: "Editorial Director & Publisher",
    company: "Datatechvibe",
    text: [],
    image: "ravi.jpg",
    company_image: undefined,
    topic: "",
  },

  {
    name: "Samir Boualla",
    link: "Samir Boualla",
    title: "Chief Data Officer",
    company: "ING, France",
    text: [
      `Samir is a senior executive manager with more than +20 years experienced Digital and Data Program Leader armed with an extensive track record of spearheading global teams to execute complex programmes. A senior executive manager providing strategic leadership for ideating & delivering user-centric digital solutions while aligning departmental goals with the long-term organizational vision. 
      Currently Samir is the Chief Data Officer at ING France and responsible for the design and implementation of the ING data-driven strategy, data platforms, data protection, data governance and the Data culture transformation. Before, Samir has been responsible for different international data strategy implementations, global transformation programs and successfully leading departments in the fields of Data Management, Information Management, Marketing, Analytics, Finance and Risk. Samir Boualla has been listed as one of the most influential data and analytics practitioners on the 2021 DataIQ 100 in the category ‘Data Titans`,
    ],
    image: "samir.jpg",
    company_image: undefined,
    topic: "",
  },
  {
    name: "Vishal Soni",
    link: "Vishal Soni",
    title: "Product Evangelist",
    company: "Alteryx",
    text: [
      `Vishal Soni joined Alteryx in 2018 to grow the Middle East and Africa market. During his first two years focused on enabling users to solve their analytics use cases . Currently, he transitioned into positions such as Technical Product Marketing Manager and Product Evangelist, where he is responsible for developing technical solutions, content and thought leadership for new and existing customers. Prior to his journey with Alteryx, for several years he held positions within finance and banking including Eze Software, Nomura and HSBC Hong Kong.`,
    ],
    image: "vishal.jpg",
    company_image: undefined,
    topic: "",
  },
  {
    name: "Sanjeev Madavi",
    link: "Sanjeev Madavi",
    title: "Group Chief Digital Transformation Officer",
    company: "Chalhoub Group",
    text: [
      `Sanjeev Madavi is a seasoned IT executive with 25 years of global experience in Digital transformation, Value chain innovation, New business ventures and Operational Excellence across multiple industries. He works on strategic long-term initiatives focused on capability building and delivering dynamic and innovative solutions aligned to business goals. He uses his global experience in leadership roles surrounding strategy formulation, risks and compliance, process engineering, program management, technology roadmaps, enterprise solutions delivery, and business entrepreneurship experience to deliver a positive impact to the bottom line. Sanjeev is a recognized leader in turning businesses into digital enterprises and executing business transformation alongside organizational change management.
    He is a regular speaker at conferences and symposiums on the amalgamation of business and technology ideas and has received several accolades and recognitions including the Supply Chain & Logistics Technology Leader of the Year award in 2019. Since Feb 2021, he is the Group Chief Digital Transformation Officer for the Khimji Ramdas Group looking into strategic IT investments and digital transformation agenda for the 50+ businesses under the group. Prior to this he was the Director of Technology at Asyad spearheading digital transformation in Logistics State-Owned Enterprises and the implementation of Sultanate of Oman’s 2040 Logistics Strategy (SOLS2040). He is a Civil Engineer and holds an MBA from the Indian Institute of Management Ahmedabad and has completed several executive leadership programs including the Stanford LEAD program in Corporate Innovation. He is passionate about digital-driven enterprises and speaks regularly at events concerning new-age business ecosystems and technology innovation for the future.
    `,
    ],
    image: "madavi.jpg",
    company_image: undefined,
    topic: "",
  },
  {
    name: "Dina Mohammad Laity",
    link: "Dina Mohammad Laity",
    title: "Data Science Leader	",
    company: "",
    text: [],
    image: "dina.jpg",
    company_image: undefined,
    topic: "",
  },
  {
    name: "Brice  Leporini",
    link: "Brice  Leporini",
    title: "Senior Solutions Engineer",
    company: "Confluent",
    text: [
      `Brice is a technology specialist with over 20 years of experience focused on the Java ecosystems and distributed systems. He brings with him international experience supporting enterprises across Europe and the Middle East. As a solutions engineer at Confluent, Brice, helps companies build the best enterprise-class streaming data platform with Apache Kafka at its core. In his prior roles Brice was the Chief Technology Officer at Palo IT and has also worked as a freelancer for years.`,
    ],
    image: "brice.png",
    company_image: undefined,
    topic: "",
  },
  {
    name: "Jerome Couzy ",
    link: "Jerome Couzy ",
    title: "Data Governance & Privacy Expert",
    company: "Informatica",
    text: [
      `Jerome Couzy – Data Governance & privacy Expert, Informatica, UAE
      With more than 10 years in the Analytical world, Jerome is helping companies leverage the value of their data. Jerome will share how the Informatica Intelligent Data Management platform can help enterprises accelerate their digital transformation by using understood, trusted and governed data.`,
    ],
    image: "jerome.jpg",
    company_image: undefined,
    topic: "",
  },
  {
    name: "Ammar Alfayoumi ",
    link: "Ammar Alfayoumi ",
    title: "Senior Solutions Engineer",
    company: "Cloudera",
    text: [``],
    image: "ammar.jpg",
    company_image: undefined,
    topic: "",
  },
  {
    name: "Ryan den Rooijen",
    link: "Ryan den Rooijen",
    title: "Chief Ecommerce Officer",
    company: "Chalhoub Group",
    text: [``],
    image: "ryan.jpg",
    company_image: undefined,
    topic: "",
  },
  {
    name: "Tim Carmichael",
    link: "Tim Carmichael",
    title: "Chief Data Officer",
    company: "Chalhoub Group",
    text: [``],
    image: "tim.jpg",
    company_image: undefined,
    topic: "",
  },
];

export const jury = [];
export const sponsorsnew = [
  {
    name: "Acceldata",
    pageLink: "Acceldata",
    type: "STRATEGIC SPONSOR",
    image: "acceldata.png",
    text: [
      `
      Acceldata has created the world's first Multidimensional Data Observability Cloud, helping data-driven enterprises achieve operational excellence, innovation agility, and higher returns on data investment.
<br/>
      Modern enterprises increasingly rely on embedded analytics and AI systems to power their business operations and decisions. Building, operating, and optimizing these systems, however, can be overwhelming.
      <br/> 
      Traditional monitoring tools, while effective for web applications and microservices, don’t provide sufficient insight into distributed data, processing, and pipelines.
      <br/>
      Acceldata's data observability tools use purpose-built analytics and monitoring to optimize embedded AI and analytics workloads.
      <br/>
      Customers like GE Digital, PhonePe (Walmart), Pubmatic, Pratt & Whitney, DBS Bank, and Oracle depend on Acceldata to decrease data pipeline costs by over 20%, while improving reliability by more than 95%. Finance and business teams love the 15+% increase in return on data investments.
      <br/>
      Acceldata is now building the industry's first Data Observability Cloud for hybrid data lakes and cloud data warehouses. Acceldata's Data Observability Cloud provides on-demand operational intelligence to support embedded AI and analytics data workloads. With Acceldata, enterprises can easily scale pipelines to meet the needs of modern business, regardless of platform or cloud environment.
      `,
    ],
    link: "https://www.acceldata.io/",
    grid: 4,
  },
  {
    name: "BBi",
    pageLink: "BBI",
    type: "GOLD SPONSOR",
    image: "BBI LOGO_800X533-01.png",
    text: [
      `BBI is an award-winning global information technology solutions provider helping enterprises survive today’s business disruptions. From digital strategies to Cloud native enterprise data management and advanced big data analytics, we’ve provided our clients innovative data driven digital transformation solutions that really make the change happen.`,
    ],
    link: "https://www.informatica.com",
    grid: 4,
  },
  {
    name: "Snowflake",
    pageLink: "Snowflake",
    type: "GOLD SPONSOR",
    image: "snowflake_logo-01-01 (1) (1).jpg",
    text: [``],
    link: "https://www.snowflake.com/",
    grid: 4,
  },
  {
    name: "DATAIKU",
    pageLink: "DATAIKU",
    type: "SILVER SPONSOR",
    image: "Dataiku.png",
    text: [
      `Dataiku is the platform for Everyday AI, systemizing the use of data for exceptional business results. Organizations that use Dataiku elevate their people (whether technical and working in code or on the business side and low- or no-code) to extraordinary, arming them with the ability to make better day-to-day decisions with data.
<br/><br/>
      More than 450 companies worldwide use Dataiku to systemize their use of data and AI, driving diverse use cases from fraud detection to customer churn prevention, predictive maintenance to supply chain optimization, and everything in between.`,
    ],
    link: "https://www.dataiku.com/",
    grid: 4,
  },
  {
    name: "denodo",
    pageLink: "Denodo",
    type: "SILVER SPONSOR",
    image: "denodo.png",
    text: [
      `Denodo is the leader in data virtualization providing agile, high performance data integration, data abstraction, and real-time data services across the broadest range of enterprise, cloud, big data, and unstructured data sources at half the cost of traditional approaches. Denodo’s customers across every major industry have gained significant business agility and ROI by enabling faster and easier access to unified business information for agile BI, big data analytics, Web, and cloud integration, single-view applications, and enterprise data services`,
    ],
    link: "https://www.denodo.com/en",
    grid: 4,
  },
  {
    name: "confluent",
    pageLink: "confluent",
    type: "SILVER SPONSOR",
    image: "confluent.png",
    text: [``],
    link: "https://www.confluent.io/",
    grid: 4,
  },
  {
    name: "Alpha data",
    pageLink: "Alpha data",
    type: "NETWORKING PARTNER",
    image: "alpha.jpg",
    text: [``],
    link: "",
    grid: 4,
  },
  {
    name: "Dremio",
    pageLink: "Dremio",
    type: "NETWORKING PARTNER",
    image: "dremio.jpg",
    text: [
      `Dremio is the lake house company. Hundreds of enterprises around the world, including brands like CIH Bank S.A. Qatar Airways, National Development Fund of Saudi Arabia, and more use Dremio’s SQL engine to deliver mission-critical BI on the lake. As the original creator of Apache Arrow, Dremio is on a mission to reinvent SQL for data lakes and meet customers where they are in their cloud or on premise journey. To learn more, follow the company on GitHub, LinkedIn, Twitter, and Facebook, or visit www.dremio.com.`,
    ],
    link: "",
    grid: 4,
  },
  {
    name: "Qlik Finesse",
    pageLink: "Qlik Finesse",
    type: "ANALYTICS PARTNER",
    image: "QLIK_logo 800X533-01.png",
    text: [
      `<b> Qlik</b><br/>
      Qlik helps enterprises around the world move faster, work smarter, and lead the way forward with an
end-to-end solution for getting value out of data. Our Active Intelligence Platform® is the only one on
the market that allows for open-ended, curiosity-driven exploration, giving everyone – at any skill
level – the ability to make real discoveries that lead to real outcomes and transformative changes.
With more than 50,000 active customers globally, Gartner has recognized Qlik as a leader in the MQ
for Analytics and Business Intelligence for the 12th year in a row and the vendor with the highest
annual growth in the data integration sector.<br/>
Our vision is for a data-literate world. Where people, businesses, organizations, and governments
tackle their most complex challenges with data. That’s what excites us here at Qlik<br/>
Qlik is trusted by hundreds of enterprises across the Middle-East region and is also the Official
Analytics Partner of the Fortune 500®<br/><br/>
<b>Finesse</b><br/>
      With a vision to build a global system integration company which will positively impact the lives
of people, Finesse was incorporated a decade ago. With their 400+ dedicated professionals and
350+ enterprise clients, Finesse continues to enable and secure their clients digital
transformation journey across BFSI, Education, Energy, Healthcare, Public Sector, Telecom,
Travel &amp; Logistics among others. Finesse digital transformation portfolio includes solutions &amp;
services for AI Chatbots, BI &amp; Analytics, Blockchain, CRM / CEM, Cloud, Corporate Treasury
Management, ECM, RPA, Infra &amp; Applications Managed Services, Cyber Security, SOC
services among others. Finesse has also won several awards and accolades for its contribution
in transforming the technology landscape in the region.`,
    ],
    link: "",
    grid: 4,
  },
  {
    name: "Beinex",
    pageLink: "Beinex",
    type: "EXHIBITOR",
    image: "beinex.jpg",
    text: [
      `Beinex is a multinational firm exploring the endless possibilities of data for Cloud, Analytics, Artificial Intelligence, Machine Learning, and Automation. In effect, Beinex architects, guides, leads, and implements solutions in Analytics, AI, and ML for the spheres of Digital Transformation, GRC, and Risk & Audit Transformation. Partnerships are what make Beinex stronger. The company has strong partnerships with some of the leading technology firms, research labs, and universities around the globe. Businesses can leverage the power of our partner ecosystem to maximize the value of their end-to-end analytics journey. Present in three continents, Beinex enables its clients to analyze data,
       mitigate risks, identify opportunities and automate processes.
      `,
    ],
    link: "",
    grid: 4,
  },
  {
    name: "denodo",
    pageLink: "Denodo",
    type: "GOLD SPONSOR",
    image: "denodo.png",
    text: [
      `Denodo is the leader in data virtualization providing agile, high performance data integration, data abstraction, and real-time data services across the broadest range of enterprise, cloud, big data, and unstructured data sources at half the cost of traditional approaches. Denodo’s customers across every major industry have gained significant business agility and ROI by enabling faster and easier access to unified business information for agile BI, big data analytics, Web, and cloud integration, single-view applications, and enterprise data services`,
    ],
    link: "https://www.denodo.com/en",
    grid: 4,
  },
  {
    name: "BBi",
    pageLink: "BBI",
    type: "GOLD SPONSOR",
    image: "BBI LOGO_800X533-01.png",
    text: [
      `
      <br/>
      <b><strong>Confluent</strong></b> is pioneering a fundamentally new category of data infrastructure centered around data in motion. Our cloud-native offering is designed to be the intelligent connective tissue enabling real-time data, from multiple sources, to constantly stream across the organization. 
<br/><br/>
      We believe data in motion is at the center of the next major wave of technology innovation companies are undergoing. To accomplish this, we need data infrastructure that supports collecting a continuous flow of data from across the company and building applications that process and react to that flow of data in real-time. In other words, as a company increasingly becomes software-defined, it needs a data platform built for data in motion. 
      <br/><br/> 
      With Confluent, our customers can meet the new business imperative of delivering rich, digital customer experiences and real-time business operations. Our mission is to set data in motion so any organization can innovate and win in a digital-first world.
      `,
      `<p><strong>For more information,&nbsp;visit&nbsp;<a href="https://www.confluent.io/">https://www.confluent.io/</a></strong></p><br/><br/>`,
      `<b><strong>BBI</strong></b> is an award-winning global information technology solutions provider helping enterprises survive today’s business disruptions. From digital strategies to Cloud native enterprise data management and advanced big data analytics, we’ve provided our clients innovative data driven digital transformation solutions that really make the change happen.`,
      `<p><strong>For more information,&nbsp;visit&nbsp;<a href="https://bbi-consultancy.com/">https://bbi-consultancy.com/</a></strong></p><br/><br/>`,
    ],
    link: "",
    grid: 4,
  },
  // {
  //   name: "confluent",
  //   pageLink: "confluent",
  //   type: "GOLD SPONSOR",
  //   image: "confluent.png",
  //   text: [``],
  //   link: "https://www.confluent.io/",
  //   grid: 4,
  // },
  {
    name: "Aruba",
    pageLink: "Aruba",
    type: "SILVER SPONSOR",
    image: "aruba.jpg",
    text: [
      `Aruba, a Hewlett Packard Enterprise company, is a leading provider of next-generation networking solutions for enterprises of all sizes worldwide. We deliver IT solutions that empower organizations to serve the latest generation of mobile-savvy users who rely on cloud-based business apps for every aspect of their work and personal lives.`,
    ],
    link: "https://www.arubanetworks.com/",
    grid: 4,
  },
  {
    name: "DATAIKU",
    pageLink: "DATAIKU",
    type: "SILVER SPONSOR",
    image: "Dataiku.png",
    text: [
      `Dataiku is the platform for Everyday AI, systemizing the use of data for exceptional business results. Organizations that use Dataiku elevate their people (whether technical and working in code or on the business side and low- or no-code) to extraordinary, arming them with the ability to make better day-to-day decisions with data.
<br/><br/>
      More than 450 companies worldwide use Dataiku to systemize their use of data and AI, driving diverse use cases from fraud detection to customer churn prevention, predictive maintenance to supply chain optimization, and everything in between.`,
    ],
    link: "https://www.dataiku.com/",
    grid: 4,
  },
  {
    name: "Coursera",
    pageLink: "Coursera",
    type: "SILVER SPONSOR",
    image: "coursera.jpg",
    text: [
      `Transforming organizations with high-impact skills development
<br/><br/>
    Coursera partners with more than 250 leading universities and companies to bring flexible, affordable, job-relevant online learning to individuals and organizations worldwide. We offer a range of learning opportunities, from hands-on projects and courses to job-ready certificates, career credentials, and degree programs.
    <br/><br/>
    Coursera was founded by Daphne Koller and Andrew Ng with a vision of providing life-transforming learning experiences to learners around the world. Today, 100+ million learners, 100+ Fortune 500 companies, and more than 7,000 campuses, businesses, and governments come to Coursera to access world-class learning anytime, anywhere. `,
    ],
    link: "http://www.coursera.org/business",
    grid: 4,
  },
  {
    name: "Accord Business Group",
    pageLink: "Accord Business Group",
    type: "SILVER SPONSOR",
    image: "ABG.jpg",
    text: [
      `ABG provides next-generation Technology Advisory & Implementer as ICT System Integrator on behalf of clients across verticals.
<br/>
<br/>
      ABG has been the preferred partner for SAS supporting clients across the Middle East, South Asia and, Africa since 2014. 
      <br/><br/> 
      ABG is on the way to becoming a data-driven company focusing on technologies that will help customers across sectors build, enhance, and
      leverage data sources for forecasting and decision-making.`,
    ],
    link: "https://accordbgroup.com/",
    grid: 4,
  },
  {
    name: "SAP",
    pageLink: "SAP",
    type: "SILVER SPONSOR",
    image: "SAP.png",
    text: [
      `As the market leader in enterprise application software, SAP is helping companies of all sizes and in all industries run better by redefining ERP and creating networks of intelligent enterprises that provide transparency, resiliency, and sustainability across supply chains. Our end-to-end suite of applications and services enables our customers to operate profitably, adapt continuously, and make a difference worldwide.`,
    ],
    link: "http://sap.com/mena",
    grid: 4,
  },
  {
    name: " Informatica & Magnoos",
    pageLink: " Informatica-and-Magnoos",
    type: "DATA MANAGEMENT PARTNER",
    image: "informatica.jpg",
    text: [
      `<p><strong>MAGNOOS&nbsp;</strong>is a leading regional company delivering innovation-led strategy, technology, and business consulting services founded in 2012 by experts in the Data Management &amp; Analytics, Service Management and Automation software solutions industry.</p>
      <p>MAGNOOS offers a range of consulting and residency services, while undertaking the analysis, design and implementation of Information Technologies across a number of specialization areas. Its success and reputation have been built upon the ability to manage and own projects end to end, ensuring all aspects of the project are completed and delivered according to customer requirements. We are a &ldquo;boutique shop&rdquo; of the world&rsquo;s leading Data Management &amp; Analytics, Business Intelligence, Big Data, Digital Service Management, Digital Business Automation and Robotic Process Automation Technology Vendors and Partners. We can independently advise our customers to select and implement the right solutions for their specific needs, industry in order to meet their business objectives. This is done by our consulting, professional services and support teams who are working hand in hand with our customers to quickly and effectively define and realize business benefits from their investment in modern</p>
      <p><strong>For more information,&nbsp;visit&nbsp;<a href="http://www.magnoos.com/" data-saferedirecturl="https://www.google.com/url?q=http://www.magnoos.com/&amp;source=gmail&amp;ust=1659083478957000&amp;usg=AOvVaw24McewRV_1ub7jCd6i-KW8">www.magnoos.com</a>&nbsp;Connect with Magnoos on&nbsp;<a href="https://www.linkedin.com/company/2938649/admin/" data-saferedirecturl="https://www.google.com/url?q=https://www.linkedin.com/company/2938649/admin/&amp;source=gmail&amp;ust=1659083478957000&amp;usg=AOvVaw0p3m2gIXnIGkt7-spy9GX9">LinkedIn</a></strong></p>
      <p><strong>Informatica&nbsp;</strong>(NYSE:INFA),&nbsp;an Enterprise Cloud Data Management leader, empowers businesses to realize the transformative power of data. We have pioneered a new category of software, the Informatica Intelligent Data Management Cloud&auml;&nbsp;(IDMC), powered by AI and a cloud-first, cloud-native, end-to-end data management platform that connects, manages, and unifies data across any multi-cloud, hybrid system, empowering enterprises to modernize and advance their data strategies. Over 5000 customers in more than 100 countries and 84 of the Fortune 100 rely on Informatica to drive data-led digital transformation.</p>
      <p><strong>For more information,&nbsp;visit&nbsp;<a href="http://informatica.com/" data-saferedirecturl="https://www.google.com/url?q=http://informatica.com/&amp;source=gmail&amp;ust=1659083478957000&amp;usg=AOvVaw2GwvypnUmfrwUM4ttpjzUo">informatica.com</a>. Connect with Informatica on&nbsp;<a href="https://www.linkedin.com/company/informatica/" data-saferedirecturl="https://www.google.com/url?q=https://www.linkedin.com/company/informatica/&amp;source=gmail&amp;ust=1659083478957000&amp;usg=AOvVaw2MSDht8d8Jb9Oe2jdrkt0W">LinkedIn</a>,&nbsp;<a href="http://twitter.com/Informatica" data-saferedirecturl="https://www.google.com/url?q=http://twitter.com/Informatica&amp;source=gmail&amp;ust=1659083478957000&amp;usg=AOvVaw1SNZeS23Y9irjWBz3q_6L3">Twitter</a>&nbsp;and&nbsp;<a href="https://www.facebook.com/InformaticaLLC" data-saferedirecturl="https://www.google.com/url?q=https://www.facebook.com/InformaticaLLC&amp;source=gmail&amp;ust=1659083478957000&amp;usg=AOvVaw0yA3TAVj1MXqdDnuDbko-j">Facebook</a>.</strong></p>`,
    ],
    link: "",
    grid: 4,
  },

  {
    name: "Beinex",
    pageLink: "Beinex",
    type: "NETWORKING SPONSOR",
    image: "beinex.jpg",
    text: [
      `Beinex is a multinational firm exploring the endless possibilities of data for Cloud, Analytics, Artificial Intelligence, Machine Learning, and Automation. In effect, Beinex architects, guides, leads, and implements solutions in Analytics, AI, and ML for the spheres of Digital Transformation, GRC, and Risk & Audit Transformation. Partnerships are what make Beinex stronger. The company has strong partnerships with some of the leading technology firms, research labs, and universities around the globe. Businesses can leverage the power of our partner ecosystem to maximize the value of their end-to-end analytics journey. Present in three continents, Beinex enables its clients to analyze data, mitigate risks, identify opportunities and automate processes.

      `,
    ],
    link: "",
    grid: 4,
  },
  {
    name: "ManageEngine",
    pageLink: "ManageEngine",
    type: "NETWORKING SPONSOR",
    image: "ME_Logo.jpg",
    text: [
      `As the IT management division of Zoho Corporation, ManageEngine prioritizes flexible solutions that work for all businesses, regardless of size or budget.

      ManageEngine crafts comprehensive IT management software with a focus on making your job easier. Our 120+ award-winning products and free tools cover everything your IT needs. From network and device management to security and service desk software, we're bringing IT together for an integrated, overarching approach to optimize your IT.`,
    ],
    link: "https://www.manageengine.com/",
    grid: 4,
  },
  {
    name: "Qlik - Master-Works",
    pageLink: "Qlik-Master-Works",
    type: "EXHIBITOR",
    image: "qlik.png",
    text: [
      `<br/><p><strong><b>About Qlik</b></strong>:</p><br/>`,
      `<strong>Qlik's</strong> vision is a data-literate world, where everyone can use data and analytics to improve decision-making and solve their most challenging problems. Our cloud-based Qlik Active Intelligence Platform delivers end-to-end, real-time data integration and analytics cloud solutions to close the gaps between data, insights and action. By transforming data into Active Intelligence, businesses can drive better decisions, improve revenue and profitability, and optimize customer relationships. Qlik does business in more than 100 countries and serves over 38,000 active customers around the world.`,
      `<p><strong>For more information,&nbsp;visit&nbsp;<a href="https://www.qlik.com/?utm_content=VelocitySaudi&utm_medium=referral&utm_mpt_id=CJ9DHP">https://qlik.com</a></strong></p><br/><br/>`,
      `<p><strong><b>About Master Works</b></strong>:</p><br/>`,
      `<strong>Master Works</strong> has expertise in products and services across multiple technology fields such as data management, artificial intelligence, data strategy, API management, software development, and Robotic Process Automation (RPA). With more than 10 years of experience in Data Management and Digitization Domains, Master Works’ methodologies for Data Warehousing/Business Intelligence, Data Strategy, Advance Data Analytics, Digital Transformation, and API Management have proven to be key success factors that provide optimal value to organizations. Digital Transformation is fast changing business expectations, and there is an urgent need for leaders like Master Works in this space. `,
      `<p><strong>For more information,&nbsp;visit&nbsp;<a href="https://master-works.net/">https://master-works.net/</a></strong></p><br/><br/>`,
    ],
    grid: 4,
    link: "",
  },
  {
    name: "Gulf Business Solutions",
    pageLink: "Gulf Business Solutions",
    type: "EXHIBITOR",
    image: "gbs.jpg",
    text: [
      "Gulf Business Solutions (GBS) is the leading information and technology solutions provider in the kingdom of Saudi Arabia. Since GBS started, we have grown to an enterprise supported by over five hundred employees, with a rich portfolio of products and services ranging from end-to-end digital transformation solutions to printing systems.",
      "GBS enables organizations and businesses in their journey to a digitalized business environment with cutting edge Information and Technology Solutions. We source and select only the most advanced products and solutions through the many strategic partnership we’ve developed over the years with some of the world’s leading technology companies.",
      "GBS powers the future by creating advanced solutions that support the digitalization of all enterprise and human development projects. With unmatched experience in digital innovations, we constantly re-invent ourselves by expanding on a robust portfolio of digital products and in-depth technical expertise to support the digital transformation of clients in every major industry.",
    ],
    link: "https://www.gbs-saudi.com/",
    grid: 4,
  },
  {
    name: "Reprise",
    pageLink: "Reprise",
    type: "EXHIBITOR",
    image: "reprise.jpg",
    text: [
      `Reprise is a Digital Marketing Transformation agency that is focused on leveraging technology and data to drive marketing efficiencies and return on investment for brands across the region. With extensive capabilities across data science, measurement & analytics, personalization and experience optimisation our team of consultants are well placed to support any brands Digital Marketing Transformation efforts.`,
    ],
    grid: 4,
    link: "https://reprisedigital.com/",
  },

  {
    name: "Digital Analytics Association",
    pageLink: "Digital-Analytics-Association",
    type: "SUPPORTING ASSOCIATION",
    image: "DAA.png",
    text: [
      `The Digital Analytics Association (DAA) is a not-for-profit, volunteer-powered association whose mission is to advance the use of data to understand and improve the digital world through professional development and community. Its vision a better digital world through data. The DAA was founded as the Web Analytics Association in 2004. The organization has 5,500+ members around the world, representing a broad spectrum of expertise. For more information about the DAA, or to become a member.`,
    ],
    link: "https://www.digitalanalyticsassociation.org/",
    grid: 4,
  },
];

export const pastsponsors = [
  // {
  //   name: 'Informatica',
  //   pageLink: 'Informatica',
  //   type: 'STRATEGIC SPONSOR',
  //   image: 'informatica.png',
  //   text: [
  //     `Informatica, the Enterprise Cloud Data Management leader, empowers businesses to realize the transformative power of data. We have pioneered a new category of software, the Informatica Intelligent Data Management Cloud (IDMC), powered by AI and a cloud-first, cloud-native, end-to-end data management platform that connects, manages and unifies data across any multi-cloud, hybrid system, empowering enterprises to modernize and advance their data strategies. Customers in more than 100 countries and 85 of the Fortune 100 rely on Informatica to drive data-led digital transformation. For more information, visit us at www.informatica.com, or follow us on LinkedIn, Twitter, and Facebook.`,
  //   ],
  //   link: 'https://www.informatica.com/in/',
  //   grid: 4,
  // },
  {
    name: "Acceldata",
    pageLink: "Acceldata",
    type: "STRATEGIC SPONSOR",
    image: "acceldata.png",
    text: [
      `
      Acceldata has created the world's first Multidimensional Data Observability Cloud, helping data-driven enterprises achieve operational excellence, innovation agility, and higher returns on data investment.
<br/>
      Modern enterprises increasingly rely on embedded analytics and AI systems to power their business operations and decisions. Building, operating, and optimizing these systems, however, can be overwhelming.
      <br/> 
      Traditional monitoring tools, while effective for web applications and microservices, don’t provide sufficient insight into distributed data, processing, and pipelines.
      <br/>
      Acceldata's data observability tools use purpose-built analytics and monitoring to optimize embedded AI and analytics workloads.
      <br/>
      Customers like GE Digital, PhonePe (Walmart), Pubmatic, Pratt & Whitney, DBS Bank, and Oracle depend on Acceldata to decrease data pipeline costs by over 20%, while improving reliability by more than 95%. Finance and business teams love the 15+% increase in return on data investments.
      <br/>
      Acceldata is now building the industry's first Data Observability Cloud for hybrid data lakes and cloud data warehouses. Acceldata's Data Observability Cloud provides on-demand operational intelligence to support embedded AI and analytics data workloads. With Acceldata, enterprises can easily scale pipelines to meet the needs of modern business, regardless of platform or cloud environment.
      `,
    ],
    link: "https://www.acceldata.io/",
    grid: 4,
  },

  {
    name: "Snowflake",
    pageLink: "Snowflake",
    type: "GOLD SPONSOR",
    image: "snowflake_logo-01-01 (1) (1).jpg",
    text: [``],
    link: "https://www.snowflake.com/",
    grid: 4,
  },
  {
    name: "Alpha data",
    pageLink: "Alpha data",
    type: "NETWORKING PARTNER",
    image: "alpha.jpg",
    text: [``],
    link: "",
    grid: 4,
  },
  {
    name: "Dremio",
    pageLink: "Dremio",
    type: "NETWORKING PARTNER",
    image: "dremio.jpg",
    text: [
      `Dremio is the lake house company. Hundreds of enterprises around the world, including brands like CIH Bank S.A. Qatar Airways, National Development Fund of Saudi Arabia, and more use Dremio’s SQL engine to deliver mission-critical BI on the lake. As the original creator of Apache Arrow, Dremio is on a mission to reinvent SQL for data lakes and meet customers where they are in their cloud or on premise journey. To learn more, follow the company on GitHub, LinkedIn, Twitter, and Facebook, or visit www.dremio.com.`,
    ],
    link: "",
    grid: 4,
  },
  {
    name: "Alteryx",
    pageLink: "Alteryx",
    type: "GOLD SPONSOR",
    image: "alteryx.png",
    text: [
      `The Company Powering Analytics Automation
    We enable everyone to deliver breakthrough outcomes with automated analytics & data science
     
    Modern business is digital business, and digital business means analyzing a growing flood of data. Alteryx helps customers achieve outcomes from their data to create business-changing breakthroughs every day. From designing reliable forecasts in an unpredictable economy to developing fair and equitable compensation packages for employees, the daily business and societal challenges we face demand data-driven answers.
     
    Our human-centered, engaging analytics automation platform unifies analytics, data science, and process automation together in one solution to help you harness that flood of complex data to quickly solve real-world problems.`,
    ],
    link: "https://www.alteryx.com/",
    grid: 4,
  },

  {
    name: "Cloudera",
    pageLink: "Cloudera",
    type: "GOLD SPONSOR",
    image: "cloudera.png",
    text: [
      `At Cloudera, we believe that data can make what is impossible today, possible tomorrow. We empower people to transform complex data into clear and actionable insights. Cloudera delivers an enterprise data cloud for any data, anywhere, from the Edge to AI. Powered by the relentless innovation of the open source community, Cloudera advances digital transformation for the world’s largest enterprises.`,
    ],
    link: "https://www.cloudera.com/",
    grid: 4,
  },

  {
    name: "Cdata",
    pageLink: "Cdata",
    type: "NETWORKING SPONSOR",
    image: "cdata.png",
    text: [
      `CData Software (www.cdata.com) is a leading provider of data connectivity solutions. We make it easy to connect, integrate, and automate your enterprise data, solving even the most complex data fragmentation challenges. CData’s standards-based drivers and data integration solutions enable real-time access to hundreds of cloud-based or on-premises applications, databases, and web APIs. Make the most of your organizational data with our easy-to-use, powerful, and secure data connectivity software.`,
    ],
    link: "https://www.cdata.com/",
    grid: 4,
  },

  {
    name: "Eventible",
    pageLink: "eventible",
    type: "REVIEW PARTNER",
    image: "eventible.png",
    text: [
      "Find better events faster and meet your learning & networking objectives with Eventible - the world's first platform for event reviews from real attendees. ",
    ],
    link: "https://eventible.com/",
    imageLink: "https://eventible.com/marketing/vibe-martech-fest",
    grid: 4,
  },
  {
    name: "Digital Analytics Association",
    pageLink: "Digital-Analytics-Association",
    type: "MEDIA PARTNER",
    image: "DAA.png",
    text: [
      `The Digital Analytics Association (DAA) is a not-for-profit, volunteer-powered association whose mission is to advance the use of data to understand and improve the digital world through professional development and community. Its vision a better digital world through data. The DAA was founded as the Web Analytics Association in 2004. The organization has 5,500+ members around the world, representing a broad spectrum of expertise. For more information about the DAA, or to become a member, visit the DAA website at www.digitalanalyticsassociation.org.`,
    ],
    link: "https://www.digitalanalyticsassociation.org/",
    grid: 4,
  },
  {
    name: "Media 7",
    pageLink: "Media-7",
    type: "MEDIA PARTNER",
    image: "media7.png",
    text: [
      `"The Media 7 group is a network of media properties across 16 industries that provides marketers with multiple options to target markets, reach audiences, and develop accounts across 120 countries. Advertisers, agencies and platforms rely on Media 7 for audience engagement at scale.

      Whether it’s Travel and Hospitality, IT and Technology, Advertising, Manufacturing or Healthcare, we've got you covered. Our 31+ industry-related brands have been thoroughly inspiring, educating, and entertaining millions of audiences for years. Marketers from large enterprises to small and mid-sized businesses have been relying on Media 7 for their advertisements and marketing programs. If you're looking to build long-term relationships and trust that drives outcome then Media 7 is your one-stop destination."`,
    ],
    link: "https://media7.com/",
    grid: 4,
  },
  {
    name: "EM360",
    pageLink: "EM360",
    type: "MEDIA PARTNER",
    image: "EM360.png",
    text: [
      `EM360 is a multimedia platform that delivers tech news, opinion pieces, and educational content to the global corporate and IT communities. 

      We create, launch, and manage demand generation campaigns for our diverse and ever-growing client portfolio of enterprise technology companies.
      
      In our ten years of operation, we have spearheaded campaigns for some of the top tech businesses in the world. Our expertise, commitment, and key relationships enable us to engineer a successful campaign that not only meets your marketing goals, but delivers beyond your expectations.
      `,
    ],
    link: "https://www.em360tech.com/",
    grid: 4,
  },
  {
    name: "denodo",
    pageLink: "Denodo",
    type: "GOLD SPONSOR",
    image: "denodo.png",
    text: [
      `Denodo is the leader in data virtualization providing agile, high performance data integration, data abstraction, and real-time data services across the broadest range of enterprise, cloud, big data, and unstructured data sources at half the cost of traditional approaches. Denodo’s customers across every major industry have gained significant business agility and ROI by enabling faster and easier access to unified business information for agile BI, big data analytics, Web, and cloud integration, single-view applications, and enterprise data services`,
    ],
    link: "https://www.denodo.com/en",
    grid: 4,
  },
  {
    name: "confluent",
    pageLink: "BBI",
    type: "GOLD SPONSOR",
    image: "BBI LOGO_800X533-01.png",
    text: [
      `
      <br/>
      <b><strong>Confluent</strong></b> is pioneering a fundamentally new category of data infrastructure centered around data in motion. Our cloud-native offering is designed to be the intelligent connective tissue enabling real-time data, from multiple sources, to constantly stream across the organization. 
<br/><br/>
      We believe data in motion is at the center of the next major wave of technology innovation companies are undergoing. To accomplish this, we need data infrastructure that supports collecting a continuous flow of data from across the company and building applications that process and react to that flow of data in real-time. In other words, as a company increasingly becomes software-defined, it needs a data platform built for data in motion. 
      <br/><br/> 
      With Confluent, our customers can meet the new business imperative of delivering rich, digital customer experiences and real-time business operations. Our mission is to set data in motion so any organization can innovate and win in a digital-first world.
      `,
      `<p><strong>For more information,&nbsp;visit&nbsp;<a href="https://www.confluent.io/">https://www.confluent.io/</a></strong></p><br/><br/>`,
      `<b><strong>BBI</strong></b> is an award-winning global information technology solutions provider helping enterprises survive today’s business disruptions. From digital strategies to Cloud native enterprise data management and advanced big data analytics, we’ve provided our clients innovative data driven digital transformation solutions that really make the change happen.`,
      `<p><strong>For more information,&nbsp;visit&nbsp;<a href="https://bbi-consultancy.com/">https://bbi-consultancy.com/</a></strong></p><br/><br/>`,
    ],
    link: "",
    grid: 4,
  },
  // {
  //   name: "confluent",
  //   pageLink: "confluent",
  //   type: "GOLD SPONSOR",
  //   image: "confluent.png",
  //   text: [``],
  //   link: "https://www.confluent.io/",
  //   grid: 4,
  // },
  {
    name: "Aruba",
    pageLink: "Aruba",
    type: "SILVER SPONSOR",
    image: "aruba.jpg",
    text: [
      `Aruba, a Hewlett Packard Enterprise company, is a leading provider of next-generation networking solutions for enterprises of all sizes worldwide. We deliver IT solutions that empower organizations to serve the latest generation of mobile-savvy users who rely on cloud-based business apps for every aspect of their work and personal lives.`,
    ],
    link: "https://www.arubanetworks.com/",
    grid: 4,
  },
  {
    name: "DATAIKU",
    pageLink: "DATAIKU",
    type: "SILVER SPONSOR",
    image: "Dataiku.png",
    text: [
      `Dataiku is the platform for Everyday AI, systemizing the use of data for exceptional business results. Organizations that use Dataiku elevate their people (whether technical and working in code or on the business side and low- or no-code) to extraordinary, arming them with the ability to make better day-to-day decisions with data.
<br/><br/>
      More than 450 companies worldwide use Dataiku to systemize their use of data and AI, driving diverse use cases from fraud detection to customer churn prevention, predictive maintenance to supply chain optimization, and everything in between.`,
    ],
    link: "https://www.dataiku.com/",
    grid: 4,
  },
  {
    name: "Coursera",
    pageLink: "Coursera",
    type: "SILVER SPONSOR",
    image: "coursera.jpg",
    text: [
      `Transforming organizations with high-impact skills development
<br/><br/>
    Coursera partners with more than 250 leading universities and companies to bring flexible, affordable, job-relevant online learning to individuals and organizations worldwide. We offer a range of learning opportunities, from hands-on projects and courses to job-ready certificates, career credentials, and degree programs.
    <br/><br/>
    Coursera was founded by Daphne Koller and Andrew Ng with a vision of providing life-transforming learning experiences to learners around the world. Today, 100+ million learners, 100+ Fortune 500 companies, and more than 7,000 campuses, businesses, and governments come to Coursera to access world-class learning anytime, anywhere. `,
    ],
    link: "http://www.coursera.org/business",
    grid: 4,
  },
  {
    name: "Accord Business Group",
    pageLink: "Accord Business Group",
    type: "SILVER SPONSOR",
    image: "ABG.jpg",
    text: [
      `ABG provides next-generation Technology Advisory & Implementer as ICT System Integrator on behalf of clients across verticals.
<br/>
<br/>
      ABG has been the preferred partner for SAS supporting clients across the Middle East, South Asia and, Africa since 2014. 
      <br/><br/> 
      ABG is on the way to becoming a data-driven company focusing on technologies that will help customers across sectors build, enhance, and
      leverage data sources for forecasting and decision-making.`,
    ],
    link: "https://accordbgroup.com/",
    grid: 4,
  },
  {
    name: "SAP",
    pageLink: "SAP",
    type: "SILVER SPONSOR",
    image: "SAP.png",
    text: [
      `As the market leader in enterprise application software, SAP is helping companies of all sizes and in all industries run better by redefining ERP and creating networks of intelligent enterprises that provide transparency, resiliency, and sustainability across supply chains. Our end-to-end suite of applications and services enables our customers to operate profitably, adapt continuously, and make a difference worldwide.`,
    ],
    link: "http://sap.com/mena",
    grid: 4,
  },
  {
    name: "Informatica & Magnoos",
    pageLink: " Informatica-and-Magnoos",
    type: "DATA MANAGEMENT PARTNER",
    image: "informatica.jpg",
    text: [
      `<p><strong>MAGNOOS&nbsp;</strong>is a leading regional company delivering innovation-led strategy, technology, and business consulting services founded in 2012 by experts in the Data Management &amp; Analytics, Service Management and Automation software solutions industry.</p>
      <p>MAGNOOS offers a range of consulting and residency services, while undertaking the analysis, design and implementation of Information Technologies across a number of specialization areas. Its success and reputation have been built upon the ability to manage and own projects end to end, ensuring all aspects of the project are completed and delivered according to customer requirements. We are a &ldquo;boutique shop&rdquo; of the world&rsquo;s leading Data Management &amp; Analytics, Business Intelligence, Big Data, Digital Service Management, Digital Business Automation and Robotic Process Automation Technology Vendors and Partners. We can independently advise our customers to select and implement the right solutions for their specific needs, industry in order to meet their business objectives. This is done by our consulting, professional services and support teams who are working hand in hand with our customers to quickly and effectively define and realize business benefits from their investment in modern</p>
      <p><strong>For more information,&nbsp;visit&nbsp;<a href="http://www.magnoos.com/" data-saferedirecturl="https://www.google.com/url?q=http://www.magnoos.com/&amp;source=gmail&amp;ust=1659083478957000&amp;usg=AOvVaw24McewRV_1ub7jCd6i-KW8">www.magnoos.com</a>&nbsp;Connect with Magnoos on&nbsp;<a href="https://www.linkedin.com/company/2938649/admin/" data-saferedirecturl="https://www.google.com/url?q=https://www.linkedin.com/company/2938649/admin/&amp;source=gmail&amp;ust=1659083478957000&amp;usg=AOvVaw0p3m2gIXnIGkt7-spy9GX9">LinkedIn</a></strong></p>
      <p><strong>Informatica&nbsp;</strong>(NYSE:INFA),&nbsp;an Enterprise Cloud Data Management leader, empowers businesses to realize the transformative power of data. We have pioneered a new category of software, the Informatica Intelligent Data Management Cloud&auml;&nbsp;(IDMC), powered by AI and a cloud-first, cloud-native, end-to-end data management platform that connects, manages, and unifies data across any multi-cloud, hybrid system, empowering enterprises to modernize and advance their data strategies. Over 5000 customers in more than 100 countries and 84 of the Fortune 100 rely on Informatica to drive data-led digital transformation.</p>
      <p><strong>For more information,&nbsp;visit&nbsp;<a href="http://informatica.com/" data-saferedirecturl="https://www.google.com/url?q=http://informatica.com/&amp;source=gmail&amp;ust=1659083478957000&amp;usg=AOvVaw2GwvypnUmfrwUM4ttpjzUo">informatica.com</a>. Connect with Informatica on&nbsp;<a href="https://www.linkedin.com/company/informatica/" data-saferedirecturl="https://www.google.com/url?q=https://www.linkedin.com/company/informatica/&amp;source=gmail&amp;ust=1659083478957000&amp;usg=AOvVaw2MSDht8d8Jb9Oe2jdrkt0W">LinkedIn</a>,&nbsp;<a href="http://twitter.com/Informatica" data-saferedirecturl="https://www.google.com/url?q=http://twitter.com/Informatica&amp;source=gmail&amp;ust=1659083478957000&amp;usg=AOvVaw1SNZeS23Y9irjWBz3q_6L3">Twitter</a>&nbsp;and&nbsp;<a href="https://www.facebook.com/InformaticaLLC" data-saferedirecturl="https://www.google.com/url?q=https://www.facebook.com/InformaticaLLC&amp;source=gmail&amp;ust=1659083478957000&amp;usg=AOvVaw0yA3TAVj1MXqdDnuDbko-j">Facebook</a>.</strong></p>`,
    ],
    link: "",
    grid: 4,
  },

  {
    name: "Beinex",
    pageLink: "Beinex",
    type: "NETWORKING SPONSOR",
    image: "beinex.jpg",
    text: [
      `Beinex is a multinational firm exploring the endless possibilities of data for Cloud, Analytics, Artificial Intelligence, Machine Learning, and Automation. In effect, Beinex architects, guides, leads, and implements solutions in Analytics, AI, and ML for the spheres of Digital Transformation, GRC, and Risk & Audit Transformation. Partnerships are what make Beinex stronger. The company has strong partnerships with some of the leading technology firms, research labs, and universities around the globe. Businesses can leverage the power of our partner ecosystem to maximize the value of their end-to-end analytics journey. Present in three continents, Beinex enables its clients to analyze data, mitigate risks, identify opportunities and automate processes.

      `,
    ],
    link: "",
    grid: 4,
  },
  {
    name: "ManageEngine",
    pageLink: "ManageEngine",
    type: "NETWORKING SPONSOR",
    image: "ME_Logo.jpg",
    text: [
      `As the IT management division of Zoho Corporation, ManageEngine prioritizes flexible solutions that work for all businesses, regardless of size or budget.

      ManageEngine crafts comprehensive IT management software with a focus on making your job easier. Our 120+ award-winning products and free tools cover everything your IT needs. From network and device management to security and service desk software, we're bringing IT together for an integrated, overarching approach to optimize your IT.`,
    ],
    link: "https://www.manageengine.com/",
    grid: 4,
  },
  {
    name: "Qlik - Master-Works",
    pageLink: "Qlik-Master-Works",
    type: "EXHIBITOR",
    image: "qlik.png",
    text: [
      `<br/><p><strong><b>About Qlik</b></strong>:</p><br/>`,
      `<strong>Qlik's</strong> vision is a data-literate world, where everyone can use data and analytics to improve decision-making and solve their most challenging problems. Our cloud-based Qlik Active Intelligence Platform delivers end-to-end, real-time data integration and analytics cloud solutions to close the gaps between data, insights and action. By transforming data into Active Intelligence, businesses can drive better decisions, improve revenue and profitability, and optimize customer relationships. Qlik does business in more than 100 countries and serves over 38,000 active customers around the world.`,
      `<p><strong>For more information,&nbsp;visit&nbsp;<a href="https://www.qlik.com/?utm_content=VelocitySaudi&utm_medium=referral&utm_mpt_id=CJ9DHP">https://qlik.com</a></strong></p><br/><br/>`,
      `<p><strong><b>About Master Works</b></strong>:</p><br/>`,
      `<strong>Master Works</strong> has expertise in products and services across multiple technology fields such as data management, artificial intelligence, data strategy, API management, software development, and Robotic Process Automation (RPA). With more than 10 years of experience in Data Management and Digitization Domains, Master Works’ methodologies for Data Warehousing/Business Intelligence, Data Strategy, Advance Data Analytics, Digital Transformation, and API Management have proven to be key success factors that provide optimal value to organizations. Digital Transformation is fast changing business expectations, and there is an urgent need for leaders like Master Works in this space. `,
      `<p><strong>For more information,&nbsp;visit&nbsp;<a href="https://master-works.net/">https://master-works.net/</a></strong></p><br/><br/>`,
    ],
    grid: 4,
    link: "",
  },
  {
    name: "Gulf Business Solutions",
    pageLink: "Gulf Business Solutions",
    type: "EXHIBITOR",
    image: "gbs.jpg",
    text: [
      "Gulf Business Solutions (GBS) is the leading information and technology solutions provider in the kingdom of Saudi Arabia. Since GBS started, we have grown to an enterprise supported by over five hundred employees, with a rich portfolio of products and services ranging from end-to-end digital transformation solutions to printing systems.",
      "GBS enables organizations and businesses in their journey to a digitalized business environment with cutting edge Information and Technology Solutions. We source and select only the most advanced products and solutions through the many strategic partnership we’ve developed over the years with some of the world’s leading technology companies.",
      "GBS powers the future by creating advanced solutions that support the digitalization of all enterprise and human development projects. With unmatched experience in digital innovations, we constantly re-invent ourselves by expanding on a robust portfolio of digital products and in-depth technical expertise to support the digital transformation of clients in every major industry.",
    ],
    link: "https://www.gbs-saudi.com/",
    grid: 4,
  },
  {
    name: "Reprise",
    pageLink: "Reprise",
    type: "EXHIBITOR",
    image: "reprise.jpg",
    text: [
      `Reprise is a Digital Marketing Transformation agency that is focused on leveraging technology and data to drive marketing efficiencies and return on investment for brands across the region. With extensive capabilities across data science, measurement & analytics, personalization and experience optimisation our team of consultants are well placed to support any brands Digital Marketing Transformation efforts.`,
    ],
    grid: 4,
    link: "https://reprisedigital.com/",
  },
];

export const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy("type");
export const sponsorsPage = groupByType(sponsorsnew);

export const news = [
  {
    title: "The State Of The AI Superheroes Committee",
    image:
      "https://datatechvibe.com/wp-content/uploads/The-State-Of-The-AI-Superheroes-Committee.jpg",
    link: "https://datatechvibe.com/ai/the-state-of-the-ai-superheroes-committee/",
  },
  {
    title: "Data Governance Models of Tech Giants, What You Need to Learn",
    image:
      "https://datatechvibe.com/wp-content/uploads/2021/08/Data-Governance-Models-Of-Big-Tech-Giants-Check-Key-Cues.jpg",
    link: "https://datatechvibe.com/events/data-governance-models-of-tech-giants-what-you-need-to-learn/",
  },
  {
    title: "Who’s Winning The Next-Gen Data Warehouses Race?",
    image:
      "https://datatechvibe.com/wp-content/uploads/2021/09/Whos-Winning-The-Next-Gen-Data-Warehouses-Race.jpg",
    link: "https://datatechvibe.com/staff-articles/whos-winning-the-next-gen-data-warehouses-race/",
  },

  {
    title: "Prerequisites Of Selecting A Cloud Data Analytics Platform",
    image:
      "https://datatechvibe.com/wp-content/uploads/Prerequisites-of-selecting-a-cloud-data-analytics-platform.jpg",
    link: "https://datatechvibe.com/data/prerequisites-of-selecting-a-cloud-data-analytics-platform/",
  },
];

export const insights = [
  {
    title: "How SEHA is Leveraging Data for Patient Success",
    image:
      "https://datatechvibe.com/wp-content/uploads/Velocity-UAE-George-Yacoub-Group-Director-of-IT-Group-Chief-Information-Officer-Abu-Dhabi-Health-Services-Company-SEHA-324x160.jpg",
    link: "https://datatechvibe.com/staff-articles/how-seha-is-leveraging-data-for-patient-success/",
  },
  {
    title: "If It Works Great. Else, We “Fail Fast”",
    image:
      "https://datatechvibe.com/wp-content/uploads/Velocity-UAE-Interview-with-Ines-Ashton-Director-of-Advance-Analytics-Mars-324x160.jpg",
    link: "https://datatechvibe.com/interviews/if-it-works-great-else-we-fail-fast/",
  },
  {
    title: "There May be Multiple Versions of the Truth",
    image:
      "https://datatechvibe.com/wp-content/uploads/Velocity-UAE-Interview-with-Ali-Yaakub-Head-of-BI-and-Analytics-Tamara-1-324x160.jpg",
    link: "https://datatechvibe.com/interviews/there-may-be-multiple-versions-of-the-truth/",
  },
  {
    title: "Deep-dish Data Science: How Dodo Brands Uses AI for Dynamic Pricing",
    image:
      "https://datatechvibe.com/wp-content/uploads/Velocity-UAE-Interview-with-Andrey-Filipyev-Chief-Data-Officer-Dodo-Brands-324x160.jpg",
    link: "https://datatechvibe.com/interviews/deep-dish-data-science-how-dodo-brands-uses-ai-for-dynamic-pricing/",
  },
  {
    title: "Every Data Point Has a Story: Lessons from Expo 2020 Dubai",
    image:
      "https://datatechvibe.com/wp-content/uploads/Velocity-UAE-Every-Data-Point-Has-a-Story-Lessons-from-Dubai-Expo-2020-Dubai-Interview-Mohanaselvan-Jeyapalan-324x160.jpg",
    link: "https://datatechvibe.com/interviews/every-data-point-has-a-story-lessons-from-expo-2020-dubai/",
  },
  {
    title: "How Business Leaders Can Use Data to Fuel Innovative Product Development",
    image:
      "https://datatechvibe.com/wp-content/uploads/Velocity-UAE-Interview-with-Srinivasan-Sampath-Acting-Group-Chief-Technology-Officer-First-Abu-Dhabi-Bank-324x160.jpg",
    link: "https://datatechvibe.com/interviews/how-business-leaders-can-use-data-to-fuel-innovative-product-development/",
  },
  {
    title: "The Big Barriers to Digital Business Transformation",
    image:
      "https://datatechvibe.com/wp-content/uploads/Velocity-UAE_-Interview-with-Ram-Kumar-Chief-Data-and-Analytics-Officer-Cigna-324x160.jpg",
    link: "https://datatechvibe.com/interviews/the-big-barriers-to-digital-business-transformation/",
  },
  {
    title: "How to Build End-user Trust",
    image:
      "https://datatechvibe.com/wp-content/uploads/Velocity-UAE-Interview-with-Wessam-Abu-Regeila-Associate-Director-Enterprise-Solution-Data-Architecture-at-CIB-Egypt-324x160.jpg",
    link: "https://datatechvibe.com/interviews/how-to-build-end-user-trust/",
  },
];
