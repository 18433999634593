import React from "react";
import { Container, Row, Col, Button, Card } from "reactstrap";
import CountUp from "react-countup";

import "react-circular-progressbar/dist/styles.css";

function TextGrid() {
  const size =
    document.body.clientWidth >= 928 || document.documentElement.clientWidth >= 928 ? true : false;
  return (
    <>
      <div
        className="section pb-0"
        style={{
          paddingTop: !size && 0,
          backgroundColor: "transparent",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col
              md={10}
              xs={12}
              style={{
                backgroundColor: "#fff",
                // backgroundImage: size && `url(${require("assets/images/text1.png")})`,
                backgroundSize: "cover",
                backgroundRepeat: "none",
              }}
            >
              <div
                className=" text-dark"
                style={{ padding: size ? "3rem 3rem 5rem 3rem" : "1rem 1rem 5rem 1rem" }}
              >
                <h2 className="text-700 mt-0">Unlock the Power of Data for Business Growth</h2>
                <br />
                <p className="text-400" style={{ fontSize: "18px" }}>
                  The Data & Analytics Market is on the rise, with projections to reach USD 329.8
                  billion by 2030 and a staggering 29.9% CAGR. As the world becomes more digitised,
                  business leaders who quickly adapt to the changing landscape will lead the market
                  segment.
                  <br />
                  <br />
                  Datatechvibe is hosting the seventh edition of its flagship Data and Analytics
                  Summit, Velocity, in June 2024 at the United Arab Emirates
                  <br />
                  <br />
                  The summit aims to equip organisations with the knowledge and tools to enhance
                  decision-making capabilities. Join us for thought-provoking discussions and gain
                  valuable insights to stay ahead of the curve in the data analytics industry.
                </p>
                <br />

                <h2 className="text-700">Why Attend</h2>
                <br />
                <p className="text-400" style={{ fontSize: "18px" }}>
                  An exciting agenda filled with panel discussions, fireside chats, keynotes and
                  Q&As on the latest data and analytics trends and topics will give our 300+
                  attendees plenty to discuss. With an attendance of over 85% senior leadership, you
                  are guaranteed to learn and network with CDOs, CIOs, Directors, etc.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container style={{ marginTop: "-2rem" }}>
          <Row className="justify-content-center">
            <Col
              md={11}
              xs={12}
              style={{
                backgroundColor: "#cbfcf6",
                // backgroundImage: size && `url(${require("assets/images/text1.png")})`,
                backgroundSize: "cover",
                backgroundRepeat: "none",
                paddingBottom: "2rem",
                borderBottom: "1rem solid #fe5502",
              }}
            >
              <h2 className="text-700 text-center  mb-5" style={{ color: "#330085" }} text-center>
                WHAT TO EXPECT
              </h2>
              {size ? (
                <div className={`container-fluid row justify-content-center row-cols-5`}>
                  {content.map((c, i) => (
                    <Col
                      className={`text-center mb-2 ${i !== 0 && size && "border-dark border-left"}`}
                    >
                      <h1 className="text-700 text-primary mt-0">
                        <CountUp end={c.value} />+
                      </h1>
                      <h5 className="text-400 mt-0" style={{ color: "#330085" }}>
                        {c.title}
                      </h5>
                    </Col>
                  ))}
                </div>
              ) : (
                <div>
                  <ul>
                    {content.map((c, i) => (
                      <li key={i}>
                        <h5 className="text-700  mt-0" style={{ color: "#330085" }}>
                          {c.title}
                        </h5>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  { value: 30, title: "Expert Speakers" },
  { value: 20, title: "Industries" },
  { value: 250, title: "Attendees" },
  { value: 200, title: "Companies" },
  { value: 20, title: "Hyper-focused Summit Sessions" },
];
export default TextGrid;
