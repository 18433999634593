import React from "react";
import { Table } from "reactstrap";

function SponsorContent() {
  return (
    <>
      <h3 className="text-700 m-0 mb-2 text-white text-left">Learn more about Sponsorship</h3>

      <p className="text-400 text-white">
        Are you looking for new ways to boost your brand's visibility and generate high-quality
        leads? Then, Velocity Data and Analytics Summit is the perfect opportunity for your company
        to achieve these goals.
        <br />
        <br />
        Velocity is the premier Data and Analytics conference that brings together the brightest
        minds and top companies in the industry. With over 250+ attendees expected this year, your
        company can showcase your brand, products/services, and thought leadership to a highly
        engaged audience of decision-makers and influencers.
      </p>
      <div className="embed-responsive embed-responsive-16by9 mt-4">
        <iframe
          src={`https://www.youtube.com/embed/Pap9Ydx-SRg `}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Pap9Ydx-SRg "
        ></iframe>
      </div>
    </>
  );
}
function RegisterContent() {
  return (
    <>
      <h3 className="text-700 m-0 my-2 text-white text-left">
        ABOUT VELOCITY DATA AND ANALYTICS SUMMIT
      </h3>
      <p className="text-400 text-white">
        Datatechvibe is proud to announce the third annual edition of its flagship Data and
        Analytics Summit, Velocity. To be held in June 2024 in Dubai, the summit aims to equip
        organisations with the knowledge and tools to enhance decision-making capabilities.
        <br />
        <br />
        Through a series of thought-provoking discussions, attendees will gain valuable insights
        into improving accountability, developing core scaling strategies, increasing productivity,
        and making refined predictions. This is an unparalleled opportunity to gain a competitive
        edge, and stay ahead of the curve in the data analytics industry.
      </p>
      <br />
      <h3 className="text-700 m-0 my-2 text-white text-left text-uppercase">
        WHY YOU SHOULD ATTEND VELOCITY
      </h3>
      <p className="text-400 text-white">
        <ul>
          <li>Take part in hyper-focused discussions</li>
          {/* <li>Get skilled with our Workshops</li> */}
          <li>Network with 300+ Data leaders</li>
          <li>Hear from top 30+ renowned Speakers</li>
          <li>Interact with 20+ Industries</li>
        </ul>
      </p>
    </>
  );
}

function SideImage({ type }) {
  return (
    <div className="py-5">
      {/* <h3 className="text-700 m-0 my-2 text-primary text-left d-flex">
        <span className="mr-3">ABOUT</span>
        <a href="https://www.martechvibe.com/">
          <img
            src={require("assets/images/logo/martechvibelogo.png")}
            alt="martechvibe"
            width="150px"
          />
        </a>
      </h3>
      <p className="text-400 text-dark">
        Martechvibe is the Middle East region’s only and the world’s fastest-growing media platform
        dedicated to MarTech & AdTech. Since 2018, it has been delivering vital intelligence for the
        new era of marketing.
      </p>
      <br />
      <h3 className="text-700 m-0 my-2 text-primary text-left d-flex">
        <span className="mr-3 align-self-center mt-1">ABOUT</span>
        <a href="https://cxmtoday.com/">
          <img src={require("assets/images/logo/cxm.png")} alt="martechvibe" width="100px" />
        </a>
      </h3>
      <p className="text-400 text-dark">
        CXM Today Magazine is a global media brand on a mission to provide the greatest insight into
        customer experience through a deep understanding of all the facets that impact and
        contribute to it. With a global network of hundreds of writers, journalists, CX experts,
        consultants, and business leaders, we produce stories, interviews, reports and events to
        empower brands to make better decisions.
      </p>
      <br />
      <h3 className="text-700 m-0 my-2 text-primary text-left">
        ABOUT CX NXT - CUSTOMER EXPERIENCE SUMMIT
      </h3>
      <p className="text-400 text-dark">
        Customer experience is the biggest brand differentiator. The CX NXT summit is on a mission
        to demonstrate how technology, strategy, and culture can provide the experience that new-age
        customers demand. CX NXT will bring together global and region-based CX experts, brand, and
        product leaders in person to share insights, inspiration and experiences. The community will
        come together to exchange notes and prepare for the future of CX.
      </p> */}

      <br />
      {type === "SPONSOR" && <SponsorContent />}
      {type === "REGISTER" && <RegisterContent />}
    </div>
  );
}

export default SideImage;
