import React from "react";

function ProfilePageHeader({ title, bgColor, noPadding }) {
  return (
    <>
      <div
        style={{
          backgroundColor: bgColor,
          marginTop: !noPadding && "6rem",
        }}
        className="section py-5"
        data-parallax={true}
      >
        {title ? <h2 className="header-text text-uppercase text-center text-primary">{title}</h2> : ""}
      </div>
    </>
  );
}

export default ProfilePageHeader;
