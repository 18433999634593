import React from "react";

import Footer from "components/Footer.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import SpeakerCardOld from "components/SpeakerCardOld.js";
import Navbar from "components/Navbar.js";
import { speakers } from "./content";
import NavbarTop from "../components/NavbarTop.js";

function PrivacyPolicy() {
  return (
    <>
      <NavbarTop />
      <Navbar />
      <ProfilePageHeader title="" bgColor="#fff" />
      <SpeakerCardOld
        speakers={speakers.sort((a, b) => a.name.localeCompare(b.name))}
      />

      <Footer />
    </>
  );
}

export default PrivacyPolicy;
