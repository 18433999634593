import React from "react";
import { Container, Row, Col } from "reactstrap";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import Navbar from "components/Navbar.js";
import NavbarTop from "components/NavbarTop.js";

import Bronwyn from "./news/Bronwyn";
import Alison from "./news/Alison";

import Footer from "components/Footer.js";
import { news } from "./content";

function NewsSingle(props) {
  const News = props.match.params.news;
  const n = news.find((x) => x.linkId === News);
  return (
    <>
      <NavbarTop />
      <Navbar />

      <ProfilePageHeader title="" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Container fluid>
          <Row>
            <Col
              lg={12}
              xs={12}
              style={{
                padding: 0,
              }}
            >
              {n.id === "Bronwyn" ? <Bronwyn news={n} /> : ""}
              {n.id === "Alison" ? <Alison news={n} /> : ""}
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default NewsSingle;
