import React from "react";
import { Container, Row, Col } from "reactstrap";
import CountUp from "react-countup";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section pt-0"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container className="d-block">
          <Row>
            <Col md={12} className="align-self-center">
              <p className="text-400 text-dark">
                The Data & Analytics Market is on the rise, with projections to reach USD 329.8
                Billion by 2030, and a staggering 29.9% CAGR.
                <br />
                <br />
                As the world becomes more digitised, business leaders who quickly adapt to the
                changing landscape will lead the market segment. Businesses that fail to keep up
                will be left behind by their more agile competitors. The United Arab Emirates is
                leading the way, turning its vision of a data-driven economy into a reality.
                <br />
                <br />
                In line with UAE's vision for a data-driven economy, Datatechvibe is proud to
                announce the third annual edition of its flagship Data and Analytics Summit,
                Velocity. To be held in June 2023 in Dubai, the summit aims to equip organisations
                with the knowledge and tools to enhance decision-making capabilities.
                <br />
                <br />
                Through a series of thought-provoking discussions, attendees will gain valuable
                insights. This is an unparalleled opportunity to gain a competitive edge, and stay
                ahead of the curve in the data analytics industry.
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <h2 className="header-text m-0 my-4 text-primary text-center">
                A SUCCESSFUL 2022 EVENT
              </h2>
            </Col>
          </Row>
          <Row className="mb-5 justify-content-center">
            {content.map((c, i) => (
              <Col
                lg={3}
                xs={6}
                className={`text-center ${i !== 0 && size && "border-dark border-left"}`}
              >
                <h1 className="text-700 text-primary">
                  <CountUp end={c.value} duration={2.75} />+
                </h1>
                <h3 className="text-400 text-dark mt-0">{c.title}</h3>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  { value: 20, title: "Expert Speakers" },
  { value: 10, title: "Industries" },
  { value: 280, title: "Registrations" },
  { value: 60, title: "companies" },
];

export default TextGrid;
