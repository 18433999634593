import React from "react";
import Countdown from "react-countdown";
import { Button } from "reactstrap";

function ProfilePageHeader({ title, bgColor }) {
  const size =
    document.body.clientWidth >= 928 || document.documentElement.clientWidth >= 928 ? true : false;
  return null;

  return (
    <>
      <div
        style={{
          backgroundColor: "#cbfcf6",
        }}
        className="py-3 fixed-bottom d-flex justify-content-center"
        data-parallax={true}
      >
        <h5 className="text-center text-primary text-700 my-0 align-self-center mr-3">
          {size ? (
            <>
              Events starts in <Countdown date={new Date(1686038429000)} renderer={renderer} />
            </>
          ) : (
            <>
              Events starts in <br />
              <Countdown date={new Date(1686038429000)} renderer={mobileRenderer} />
            </>
          )}
        </h5>
        <Button
          href="/register"
          className="navbar-item-custom text-400 text-white px-4 rounded-0 mr-2"
          color="default"
          style={{
            background: "#330085",
            border: 0,
          }}
          size="lg"
        >
          <span
            style={{
              color: "#fff",
            }}
            className=" text-700"
          >
            REGISTER FOR 2024
          </span>
        </Button>
        <Button
          href="/sponsor"
          className="navbar-item-custom text-400 text-white px-4 rounded-0"
          color="default"
          style={{
            background: "#330085",
            border: 0,
          }}
          size="lg"
        >
          <span
            style={{
              color: "#fff",
            }}
            className=" text-700"
          >
            BECOME A SPONSOR
          </span>
        </Button>
      </div>
    </>
  );
}

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return "SALE ENDED";
  }
  return (
    <>
      <span style={{ fontSize: "30px", fontWeight: 700 }}>{days} </span>Days :{" "}
      <span style={{ fontSize: "30px", fontWeight: 700 }}>{hours} </span> Hours :{" "}
      <span style={{ fontSize: "30px", fontWeight: 700 }}>{minutes} </span> Minutes :{" "}
      <span style={{ fontSize: "30px", fontWeight: 700 }}>{seconds} </span>Seconds
    </>
  );
};
const mobileRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return "SALE ENDED";
  }
  return (
    <>
      <span style={{ fontSize: "20px", fontWeight: 700 }}>{days}D </span> :{" "}
      <span style={{ fontSize: "20px", fontWeight: 700 }}>{hours}H </span> :{" "}
      <span style={{ fontSize: "20px", fontWeight: 700 }}>{minutes}M </span> :{" "}
      <span style={{ fontSize: "20px", fontWeight: 700 }}>{seconds}S </span>
    </>
  );
};

export default ProfilePageHeader;
