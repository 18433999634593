import React from "react";
import { Col, Table, Input, Button } from "reactstrap";
import CreateAlert from "./Alert";

function OrderInfo({
  price,
  details,
  applyCoupon,
  customer,
  onChangePromo,
  promoApplied,
  error,
  success,
  removeCoupon,
}) {
  const size =
    document.body.clientWidth >= 928 || document.documentElement.clientWidth >= 928 ? true : false;
  return (
    <>
      <Col md="6">
        <>
          <h3 className="text-700 m-0 my-2 text-white text-left">
            ABOUT VELOCITY DATA AND ANALYTICS SUMMIT
          </h3>
          <p className="text-400 text-white">
            Datatechvibe is proud to announce the third annual edition of its flagship Data and
            Analytics Summit, Velocity. To be held in June 2023 in Dubai, the summit aims to equip
            organisations with the knowledge and tools to enhance decision-making capabilities.
            <br />
            <br />
            Through a series of thought-provoking discussions, attendees will gain valuable insights
            into improving accountability, developing core scaling strategies, increasing
            productivity, and making refined predictions. This is an unparalleled opportunity to
            gain a competitive edge, and stay ahead of the curve in the data analytics industry.
          </p>

          {customer.udf && customer.udf.interestedIn === "SUMMIT_AND_WORKSHOP" && (
            <>
              <h3 className="text-700 m-0 my-2 text-white text-left">Day 1 Workshop Pass</h3>
              <p className="text-400 text-white">
                <Table bordered={true}>
                  <tbody>
                    <tr>
                      <td className="text-white">
                        Early Bird Price
                        <br />
                        Save flat 20%
                      </td>
                      <td className="text-white">
                        <span className="text-700" style={{ fontSize: "20px" }}>
                          $799
                        </span>
                        <br />
                        Valid till 15th May
                      </td>
                    </tr>
                    <tr>
                      <td className="text-white">Standard Price</td>
                      <td className="text-white">
                        <span className="text-700" style={{ fontSize: "20px" }}>
                          $999
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-white">Group Pricing</td>
                      <td className="text-white">
                        Reach out to{" "}
                        <a href="mailto:marketing@datatechvibe.com" className="text-white">
                          marketing@datatechvibe.com
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </p>
              <Table className="text-white">
                <thead>
                  <tr>
                    <th>Pricing Option</th>
                    <th>Base Price</th>
                    <th>Service Fee (2.5%)</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span className="text-700">{details.description}</span>
                      <br />
                    </td>
                    <td>
                      <h3 className="mt-0">${Math.round(price / 1.025)}</h3>
                    </td>
                    <td>
                      <h3 className="mt-0">${(price - price / 1.025).toFixed(2)}</h3>
                    </td>
                    <td>
                      <h3 className="text-700 mt-1">${parseFloat(price).toFixed(2)}</h3>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="p-3 d-flex w-100">
                <Input
                  type="text"
                  name="promo"
                  id="promo"
                  value={customer.promo || ""}
                  onChange={onChangePromo}
                  disabled={promoApplied}
                  placeholder="Promo Code"
                  className="w-50 mr-3"
                />

                {promoApplied ? (
                  <Button
                    className="align-self-end ml-1 text-white rounded-0"
                    style={{
                      background: "#eb6229",
                      border: 0,
                    }}
                    onClick={removeCoupon}
                  >
                    <i class="fa fa-times"> </i>Remove Promo
                  </Button>
                ) : (
                  <Button
                    style={{
                      background: "#eb6229",
                      border: 0,
                    }}
                    className="align-self-end rounded-0 text-white"
                    onClick={applyCoupon}
                    disabled={promoApplied}
                  >
                    Apply Promo Code
                  </Button>
                )}
              </div>
            </>
          )}

          <h3 className="text-700 m-0 my-4 text-white text-left text-uppercase">
            WHY YOU SHOULD ATTEND VELOCITY
          </h3>
          <p className="text-400 text-white">
            <ul>
              <li>Take part in hyper-focused discussions</li>
              {/* <li>Get skilled with our Workshops</li> */}
              <li>Network with 300+ Data leaders</li>
              <li>Hear from top 30+ renowned Speakers</li>
              <li>Interact with 20+ Industries</li>
            </ul>
          </p>
        </>

        <CreateAlert success={success} error={error} />
      </Col>
    </>
  );
}

export default OrderInfo;
const content = [
  { value: 30, title: "Expert Speakers" },
  { value: 20, title: "Industries" },
  { value: 200, title: "Attendees" },
  { value: 150, title: "Companies" },
  { value: 15, title: "Hyper-Focused CX Sessions" },
  { value: 2, title: "CX deep-dive workshop sessions ", hidePlus: true },
];
