import React from "react";

import { Container, Row, Col } from "reactstrap";

function KeyNotes() {
  return (
    <>
      <div
        className="section"
        style={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          {content2.map((data) => (
            <Row className="justify-content-center py-5 border-bottom">
              <Col lg={3} className="mb-3">
                <div className="pr-3">
                  <h3 className="header-text" style={{ color: "#e05051" }}>
                    {data.title}
                  </h3>
                </div>
              </Col>
              <Col lg={9} className="align-self-center">
                <h5 className="text-700">{data.subTitle}</h5>
                <p className="text-400">{data.text}</p>
              </Col>
            </Row>
          ))}
        </Container>
      </div>
    </>
  );
}

export default KeyNotes;

const content2 = [
  {
    title: "CX NXT",
    subTitle: "The Future of Customer Experience",
    text: "As customer expectation continues to rise, the whole meaning of experience and superior service is being rewritten. Seamless delivery of the service and the experience across channels is the biggest challenge marketing, customer service, and support teams face today. ",
  },
  {
    title: "CX Culture",
    subTitle: "Who Owns Customer Experience?",
    text: `The organisation’s culture decides the experience that it delivers to its customers. Brands respected for their customer experience are led by CEOs or founders who are passionate about service.`,
  },
  {
    title: "CX Technology",
    subTitle: "Opportunities & Challenges",
    text: `The new AI-powered technologies present many opportunities as organisations can personalise at scale. But ensuring seamless integration, removing data silos is still a huge challenge.`,
  },
  {
    title: "CX Retail",
    subTitle: "Is Store Dead?",
    text: `How are leading retailers deploying winning customer engagement strategies? And how are retailers designing consumer loyalty programs that reduce churn and increase lifetime customer value? `,
  },
  {
    title: "CX Design",
    subTitle: "The Evolution",
    text: `Service design is an increasing imperative in an age where digitally-savvy consumers have increasingly higher expectations of their service providers and expect a seamless experience every time.`,
  },
  {
    title: "CX Marketing",
    subTitle: "How Effective Are Your Plans?",
    text: `Martech solutions have enabled greater customer understanding and insight, allowing marketers to customise strategies and personalise customer journeys. Are they working?`,
  },
];
