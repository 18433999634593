import React from "react";

import { Container, Row, Col, Button } from "reactstrap";

function KeyNotes() {
  return (
    <>
      <div
        className="section"
        style={{
          backgroundColor: "transparent",
        }}
      >
        <Container>
          <Row>
            <Col>
              <p className="text-400 text-center mb-4 text-primary" style={{ marginTop: "-3rem" }}>
                Watch all the sessions from our 2022 edition held on the 17-18 of May 2022 in the
                UAE
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {content2.map((data) => (
              <Col lg={4} className="mb-3">
                <div className="pr-3">
                  <div class="embed-responsive embed-responsive-16by9">
                    <iframe
                      class="embed-responsive-item"
                      src={`https://www.youtube.com/embed/${data.image}`}
                      allowfullscreen
                      title="onDemand"
                    ></iframe>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <Container>
            <Row>
              <Col lg="12">
                <h2 className="header-text m-0 my-4 text-primary text-center">
                  GLIMPSES FROM THE 2022 EVENT
                </h2>
              </Col>
            </Row>

            <Row className="justify-content-center">
              {images.map((data, index) => (
                <Col md={4} xs={12} key={index} className="px-1 py-1">
                  <img src={require(`assets/ondemand/${data.image}`)} width="100%" alt="ondemand" />
                </Col>
              ))}
            </Row>
          </Container>
        </Container>
      </div>
    </>
  );
}

export default KeyNotes;

const content2 = [
  { image: "ZnezpnfLyBw" },
  { image: "ARMadY1rY5g" },
  { image: "BnEYRPODpI8" },
  { image: "5zDUnXLUvyQ" },
  { image: "zV8xREvsiHY" },
  { image: "NK3Byvhohg8" },
  { image: "ld86NWy-Q-A" },
  { image: "W_53PHh6r38" },
  { image: "t8M23hy-JOE" },
  { image: "wTCsybaNkzg" },
  { image: "C8Fk1AkIkmc" },
  { image: "ldwDDSJ0fe4" },
  { image: "L8ZizmjRYEs" },
  { image: "bcq32xZQ478" },
  { image: "4yvvEro8TvY" },
  { image: "q1thDZSyjOs" },
  { image: "Xpr4qUgqWYw" },
  { image: "ptBVK1WJ_BY" },
  { image: "L9ZSr4jFCjI" },
  { image: "0lQO3lgQfYo" },
  { image: "2Ynr0M5viwk" },
];

const images = [
  { image: "1.jpeg" },
  { image: "2.jpeg" },
  { image: "3.jpeg" },
  { image: "4.jpeg" },
  { image: "5.jpeg" },
  { image: "6.jpeg" },
  { image: "7.jpeg" },
  { image: "8.jpeg" },
  { image: "9.jpeg" },
  { image: "10.jpeg" },
  { image: "11.jpeg" },
  { image: "12.jpeg" },
  { image: "13.jpeg" },
  { image: "14.jpeg" },
  { image: "15.jpeg" },
  { image: "16.jpeg" },
  { image: "17.jpeg" },
  { image: "18.jpeg" },
];
