import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

import Footer from "components/Footer.js";
import KeyNotes from "components/OnDemand.js";
// import SponsorNew from "components/SponsorNew.js";
// import { sponsorsPage } from "./content";
import NavbarTop from "../components/NavbarTop.js";

function RegisterPage() {
  return (
    <>
      <NavbarTop />
      <Navbar />
      <ProfilePageHeader title={"2022 Edition"} bgColor='#fff' />
      <KeyNotes />
      {/* <SponsorNew sponsors={sponsorsPage} /> */}
      <Footer />
    </>
  );
}

export default RegisterPage;
