import React, { useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import NavbarTop from "../components/NavbarTop.js";
import { DataContext } from "DataContainer";

function SpeakerSingle(props) {
  const sponsorLink = props.match.params.sponsor;
  const { sponsors } = useContext(DataContext);
  const sponsor = sponsors.find((x) => x.slug === sponsorLink) || {};

  return (
    <>
      <NavbarTop />
      <Navbar />
      <ProfilePageHeader title={""} bgColor="#fff" />
      <div className="section profile-content mt-5">
        <Container>
          <div className="owner">
            <div
              className="avatar border"
              style={{
                backgroundColor: "#ffffff",
                maxWidth: "100%",
              }}
            >
              <a href={sponsor.link} target={"_blank"}>
                <img
                  alt={sponsor.name}
                  className="img-rounded img-no-padding img-responsive"
                  src={sponsor.image}
                  style={{ maxWidth: "400px", width: "100%" }}
                />
              </a>
            </div>
            <div className="name">
              <h4 className="title" style={{ marginBottom: "0" }}>
                {sponsor.name}
              </h4>
              <br />
            </div>
          </div>
          <Row>
            <Col className="" md="12">
              <p dangerouslySetInnerHTML={{ __html: sponsor.bio }}></p>
            </Col>
          </Row>
          {sponsor.url && (
            <Row>
              <Col className="" md="12">
                <p>
                  Learn More: <a href={sponsor.url}>{sponsor.url}</a>
                </p>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </>
  );
}
export default SpeakerSingle;
