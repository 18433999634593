import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {

  return (
    <>
      <Container justify-contents-center="true">
        <div className="owner">
          <div className="avatar-big text-center">
            <img
              alt=""
              className="img img-no-padding img-responsive mt-5"
              src={require(`assets/banners/${news.image}`)}
              style={{ maxwidth: "100%" }}
            />
          </div>
        </div>
        <Row>
          <Col className={"ml-auto mr-auto mt-4"} md="10">
            <h1
              className="text-900 m-0 mt-2"
              style={{
                fontSize: "2.5rem",
                color: "#fabd2f",
              }}
            >
              Data Is The Key To Marketing-Led Growth
            </h1>
            <h3 className="text-4s00 m-0 mt-2">
              Interview With Bronwyn Pretorius, Mukuru
            </h3>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto mt-4"} md="10">
            <p>
              <b>
                A marketing professional with over 14 years of experience across
                retail and financial services, Bronwyn Pretorius, Head of
                Marketing at Mukuru, and one of the speakers at Vibe Martech
                Fest South Africa, describes how data and personalisation are
                increasingly responsible for business growth. However, while
                data can unlock actionable insights, marketers are met with
                challenges in interpreting and utilising data.
              </b>
            </p>
            <br />
            <br />

            <p>
              <b>What are the key challenges that brands face today? </b>
            </p>

            <br />
            <p>
              With the ever-changing consumer landscape many brands are stuck
              trying to cater to a customer who no longer exists. Many
              management teams are the reason behind this as they are reluctant
              to change. This is where the importance of data comes into play.
              Many companies have access to what is known as Big Data, but
              struggle to interpret this data into actionable insights that
              would improve various aspects of the business. If a business does
              not realise the importance of data and hire the right team who
              will ultimately gear the business towards success then that in
              itself becomes a challenge, one that is fixable however the right
              people in the organisation need to buy into the importance first.
              <br />
              <br />A quote I reference all of the time is “Without big data
              analytics, companies are blind and deaf, wandering out onto the
              Web like deer on a freeway.” A tweet by Geoffrey Moore made in
              2012 and still so relevant today.
            </p>
            <br />
            <br />

            <p>
              <b>
                Have marketers in South Africa started adopting marketing
                technology solutions?{" "}
              </b>
            </p>
            <br />
            <p>
              Yes, I believe that many businesses in South Africa have. Those
              who were slow to the uptake have been forced to adopt various
              marketing technologies due to the pressures being placed when
              Covid-19 hit and the impact of the various lockdowns. It forced
              many organisations to innovate.
              <br />
              It is important to remember that customers have a higher
              expectation from brands these days and want to be treated like
              human beings rather than a transaction or number. Marketing
              technology solutions allow companies to communicate to their
              customers in a more personalised way, and this increases the
              affinity customers have with the brand.
              <br />
              <br />
              An example of this relates to the 8 million customers at Mukuru.
              We cater to the emerging African market which faces various
              challenges linked to how expensive data is therefore fragmenting
              which channels they use to transact with our brand. Mukuru caters
              to our customers across all possible touch points -- they are able
              to sign up and transact across USSD and Whatsapp and you’re even
              able to sign up to use our services via Facebook or talk to a
              consultant via our Facebook chatbot. We offer a robust core engine
              for transactional capabilities that can work on one bar of Edge
              signal in northern Malawi alongside working seamlessly on a data
              intensive smartphone using 4G. We've tried to ensure that a
              customer journey can be enabled with a seamless omni channel
              experience, regardless of channel the customers choose to use to
              engage.
              <br />
              <br />
              An example of this in the Mukuru space relates to Whatsapp. Of the
              channels our customers have adopted, Whatsapp comes out as one of
              the most used by our base. In 2018, Mukuru integrated with
              WhatsApp Business API to allow our customers to both create money
              transfer orders and manage their account details. This was one of
              the firsts in Africa and was a necessary implementation, as it is
              a popular communications channel in Africa today.
              <br />
              <br />
              One of the many benefits is that the technology can work anywhere.
              Mukuru customers can be in any country in which the company
              operates and still interact with Mukuru via WhatsApp.
            </p>
            <br />
            <br />
            <p>
              <b>How do marketers tackle customer data? </b>
            </p>
            <br />
            <p>
              At Mukuru we take customer data very seriously and the protection
              around keeping their information safe.
              <br />
              <br />
              Data is and should be of utmost importance to marketers. In order
              to market effectively and reduce wastage it is important to
              understand the customer, the customer's needs and how you can use
              that information in order to achieve the business goals. However
              it is not a one-size-fits-all. Customer data needs to be the
              information that also helps you classify who your customers are,
              providing the building blocks to personify who they are, which
              helps to better understand them. Not just how they engage with
              your brand but who they are and why they engage with your brand.
              Understanding the “why” is an essential part of understanding the
              customer, especially at Mukuru.
              <br />
              <br />
              By identifying the various customer sets within your organisation,
              you have the ability to tailor how you communicate with them for
              them to feel less like a transaction and more like a valued
              individual. You’re also able to expose them to other products
              within your stable that would be of interest to them, or in the
              case of Mukuru, improve their lives and take them further up the
              ladder towards financial inclusion. A simple customer insight like
              a home language might seem basic to some, however, at Mukuru, it
              is one of the first ways a customer engages with us, by
              communicating in a language that our customers understand assists
              in building our brand and trust in the minds of our customers.
              <br />
              <br />
              Ultimately, big data analytics provides the business intelligence
              you need to bring about positive change, like improving the
              customer experience, iteration of existing products or increasing
              revenue per customer.
            </p>
            <br />
            <br />
            <p>
              <b>Is offering superior customer experience a challenge?</b>
            </p>
            <br />
            <p>
              As much as it is and should be a goal of every organisation it
              doesn’t come without its challenges. Many CX teams are dealing
              with high customer expectations, intense competition, more rapid
              customer interactions across an ever-increasing number of digital
              and physical channels, and limitations imposed by legacy processes
              and technologies.
              <br />
              Today, your customers expect a consistent, high-quality experience
              as they interact with your organisation across an ever-growing set
              of touchpoints and channels. Today, leading companies are using
              customer journey analytics to meet customer’s and company’s needs
              because traditional approaches just don’t work. However, this can
              be tricky.
              <br />
              <br />
              The top challenges companies face in offering superior CX are:
              <ul>
                <li>
                  How to translate data into actionable insights and quickly
                </li>
                <li>
                  How to determine priorities and KPIs for the CX team, as the
                  CX function sits in various parts of an organisation it could
                  pose a problem when determining priorities.
                </li>
              </ul>
              Personalisation at scale is extremely difficult to achieve as you
              need to answer a few questions like what to personalise first,
              what for, by when and how or which tools to use.{" "}
            </p>
            <br />
            <br />
            <p>
              <b>
                What critical information would you expect from technology
                experts?{" "}
              </b>
            </p>
            <br />
            <p>
              How to take big data and turn it into actionable insights, and how
              to facilitate in the quickest, easiest and most affordable way the
              best CX tools can provide ROI.
            </p>
            <br />
            <br />
            <p>
              <b>How do you think Martech will evolve in the finance sector?</b>
            </p>
            <br />
            <p>
              Human experiences are expected from brands within the financial
              sector, and building trust with your customer is paramount when in
              this industry, as dealing with money is an important matter that
              customers do not take lightly.
              <br />
              <br />
              For this reason, marketing technology is a necessity to not only
              maintain existing relationships with customers but also to gain
              new customers. As our customers' needs evolve, so should the way
              the brand communicates to these customers. This includes a
              personalised approach to meet the customer where they are at and
              ensure that the brand they trust their finances with understands
              them.
            </p>
            <br />
            <br />
            <p>
              <b>
                Can you share with us some technologies which you believe will
                transform marketing?{" "}
              </b>
            </p>
            <br />
            <p>
              Any technology that supports personalisation and significant data
              simplification.
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
