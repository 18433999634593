import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

import Footer from "components/Footer.js";
import { Container, Row, Col } from "reactstrap";
import NavbarTop from "components/NavbarTop.js";

function PaymentRedirect({
  match: {
    params: { id },
  },
}) {
  const GetContent = () => {
    if (id === "CAPTURED") {
      return (
        <>
          <ProfilePageHeader title={`REGISTERED SUCCESSFULLY`} bgColor="#fff" />
          <div className="section profile-content" style={{ padding: 0, background: "#280378" }}>
            <Container>
              <Row>
                <Col lg={12} className="text-center text-white p-5 m-5">
                  <h3>
                    Thank you for registering your interest to the Velocity Data and Analytics
                    Summit. A member of our team will be in touch with you for further details about
                    the event.
                  </h3>

                  <p className="text-700 mt-3 text-white">
                    Please note: Filling out the form does not guarantee admission to the event. As
                    a standard protocol, the Organising Team will evaluate the registration based on
                    the attendee eligibility criteria. The Organising Team will get in touch with
                    you for further details should your registration be accepted.{" "}
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      );
    }
    if (id === "REGISTER_FAILED") {
      return (
        <>
          <ProfilePageHeader title={`REGISTRATION FAILED`} bgColor="#fff" />
          <div className="section profile-content" style={{ padding: 0, background: "#ef9a9a" }}>
            <Container>
              <Row>
                <Col lg={12} className="text-center text-dark p-5 m-5">
                  <h3>Something went wrong please try again.</h3>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      );
    }
    return (
      <>
        <ProfilePageHeader title={`PAYMENT FAILED`} bgColor="#fff" />
        <div className="section profile-content" style={{ padding: 0, background: "#ef9a9a" }}>
          <Container>
            <Row>
              <Col lg={12} className="text-center text-dark p-5 m-5">
                <h3>
                  The Payment has failed with the Status {id}. Please try again, or if you are
                  facing any issues please connect with our team on marketing@martechvibe.com
                </h3>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  };
  return (
    <>
      <NavbarTop />
      <Navbar />
      <GetContent />

      <Footer />
    </>
  );
}

export default PaymentRedirect;
