import React from "react";

import { Container, Row, Col, Card } from "reactstrap";
// import CountUp from 'react-countup';
import ScrollAnimation from "react-animate-on-scroll";

import "react-circular-progressbar/dist/styles.css";

function AboutPoints() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        className="section"
        style={{
          backgroundColor: "transparent",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col className="text-dark text-center mb-3" md={12}>
              <h2 className="header-text m-0 mt-4 text-white text-center">
                <span className="text-primary"> NEED A COMPELLING REASON TO ATTEND?</span>
              </h2>
            </Col>
          </Row>
          <Row className={`justify-content-center ${size ? "row-cols-5" : "row-cols-1"}`}>
            {content.map((c) => (
              <Col
                className="d-flex p-1"
                style={{
                  minHeight: size ? "320px" : "none",
                }}
              >
                <div className={`mb-4   p-3 text-dark`}>
                  <h1
                    className="text-900  mt-0"
                    style={{ color: "#ec632a", fontSize: size ? "6rem" : "3rem" }}
                  >
                    0{c.id}.
                  </h1>
                  <h5 className="text-700 mt-0">{c.title}</h5>
                  <p className="text-400 mt-0">{c.description}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AboutPoints;

const content = [
  {
    title: "Invest in Your Success",
    description:
      "Be equipped with the tools and knowledge necessary to outshine your competition and achieve success in your career.",
    id: 1,
  },

  {
    title: "Expand Your Professional Network",
    description:
      "Connect with other professionals in the data and analytics field and expand your network. ",
    id: 2,
  },
  {
    title: "Elevate Your Expertise",
    description:
      "You'll have the opportunity to learn from industry leaders, and experts with a wealth of knowledge and experience.",
    id: 3,
  },
  {
    title: "Unlock Your Data's Full Potential",
    description:
      "Delve into the opportunity to explore the cutting-edge tools and technologies that are revolutionising the data and analytics field.",
    id: 4,
  },

  {
    title: "Stay Ahead of the Standards",
    description:
      "Gain a deep understanding of the latest best practices and standards in the data and analytics field.",
    id: 5,
  },
];
