import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

import Footer from "components/Footer.js";
import Insights from "components/Insights.js";
import SponsorNew from "components/SponsorNew.js";
import { insights, sponsorsPage } from "./content";
import NavbarTop from "../components/NavbarTop.js";

function RegisterPage() {
  return (
    <>
      <NavbarTop />
      <Navbar />
      <ProfilePageHeader title={"SPEAKER INSIGHTS"} bgColor="#fff" />
      <Insights news={insights} />
      <SponsorNew sponsors={sponsorsPage} />
      <Footer />
    </>
  );
}

export default RegisterPage;
