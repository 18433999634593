import React from "react";
import { Container, Row, Col } from "reactstrap";

function TextGrid() {
  let pageHeader = React.createRef();

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section section-with-space"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col md={6}>
              <img
                src={require("assets/images/resource.png")}
                alt="resource"
                width="100%"
              />
            </Col>
            <Col md={6} className="align-self-top">
              <p className="text-400">
                One of the most challenging times for companies has exposed the
                great digital divide between marketers.
              </p>
              <p className="text-400">
                Arguably one of the most challenging years also presented key
                opportunities, Martechvibe conducted the first-ever state of
                Martech survey amongst the most influential and powerful CMOs of
                the region.
                <br />
                <br /> While 40% of the respondents saw budget cuts of over 31%
                due to COVID-19, 86% have said that it fast-tracked digital
                transformation in their companies.
                <br />
                <br /> In the survey, 70% CMOs stated that there is an overload
                of tech solutions and vendors do not provide enough information
                to make an informed decision.
              </p>

              <br />
              <br />
            </Col>

            <Col lg={12}>
              <p className="text-400">
                As key markets of the region open up, business networking is
                seen as a powerful method for developing contacts and
                opportunities. While acquiring new customers is a major priority
                for most marketers, customer retention seems to be an urgent
                focus area. <br />
                <br />
                State of Martech Survey was commissioned by Martechvibe
                Intelligence, a market research and reports division. Download
                this essential report to get a full overview.
              </p>
              <h2 className="text-700 text-center">Download Here</h2>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
