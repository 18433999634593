// reactstrap components
import React, { useEffect, useState } from "react";

import Container from "reactstrap/lib/Container";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Typist from "react-typist";
import { Button } from "reactstrap";

function LandingPageHeader({ text, location }) {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  const [modalRegister, setModalRegister] = useState(true);
  const toggleRegister = () => setModalRegister(!modalRegister);
  useEffect(() => {
    const common = {
      containment: "#bgndVideo1",
      autoPlay: true,
      mute: true,
      startAt: 0,
      opacity: 1,
      loop: false,
      ratio: "4/3",
      addRaster: true,
    };

    const videos = [
      {
        videoURL: "hn_CALywqsU",
        ...common,
      },
    ];

    window.jQuery("#bgndVideo1").YTPlaylist(videos, false);
  }, []);

  return (
    <div className="section" style={{ backgroundColor: "transparent" }}>
      <div
        style={{
          background: "#000",
          position: "absolute",
          top: 0,
          width: "100%",
          zIndex: -100,
          marginLeft: "auto",
          marginRight: "auto",
          pointerEvents: "none",
          marginTop: "3.5rem",
        }}
      >
        <div id="bgndVideo1" className="section player"></div>
      </div>

      <div className=" " style={{ paddingTop: "12rem" }} data-parallax={true}>
        <Container style={{ marginBottom: "2.5rem" }}>
          <Row className="justify-content-center">
            <Col lg={12} className="text-center">
              <h1
                className="header-text text-dark text-center mt-0"
                style={{
                  fontSize: size ? "4rem" : "2rem",
                  letterSpacing: !size && "5px",
                }}
              >
                <Typist cursor={{ show: false }} avgTypingDelay={100}>
                  DATA IS
                  <br />
                  EVERYTHING
                </Typist>
              </h1>
            </Col>
            <Col lg={8}>
              <h4 className="text-700 text-center mt-0 text-dark">WILL RETURN IN JUNE 2024</h4>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="pt-3 text-center">
              <Button
                href="/register"
                className="rounded-0 text-700 px-3 mr-2 border-0"
                style={{
                  background: "#2b007b",
                }}
                size="lg"
              >
                <span
                  style={{
                    fontSize: "20px",
                  }}
                >
                  REGISTER FOR 2024
                </span>
              </Button>{" "}
              {/* <Button
                href="/workshop"
                className="rounded-0 text-700 px-3 mr-2 border-0"
                style={{
                  background: "#2b007b",
                }}
                size="lg"
              >
                <span
                  style={{
                    fontSize: "20px",
                  }}
                >
                  REGISTER FOR WORKSHOP
                </span>
              </Button> */}
              <Button
                href="/sponsor"
                className="rounded-0 text-700 px-4 border-0"
                style={{
                  background: "#2b007b",
                }}
                size="lg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span
                  style={{
                    fontSize: "20px",
                  }}
                >
                  BECOME A SPONSOR
                </span>
              </Button>
            </Col>

            <Col lg={12} className="pt-3 text-center"></Col>
          </Row>
        </Container>
      </div>
      {/* <Modal
        isOpen={modalRegister}
        toggle={toggleRegister}
        className="modal-dialog-centered"
        style={{ maxWidth: "400px", maxHeight: "300px" }}
      >
        <ModalBody className="p-0">
          <div style={{ position: "absolute", right: 0 }}>
            <Button color="link" className="p-0" onClick={toggleRegister}>
              <i class="fa fa-close"></i>
            </Button>
          </div>
          <a href="https://velocityda.com/workshop?utm_source=video_roadblocker&utm_medium=eventpage&utm_campaign=video_roadblocker_eventpage">
            <img
              src={require(`assets/images/WEBSITE-ROADBLOCKER- (1).gif`)}
              width="100%"
              alt="ondemand"
            />
          </a>
        </ModalBody>
      </Modal> */}
    </div>
  );
}

export default LandingPageHeader;
