import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {

  return (
    <>
      <Container justify-contents-center="true">
        <div className="owner">
          <div className="avatar-big text-center">
            <img
              alt=""
              className="img img-no-padding img-responsive mt-5"
              src={require(`assets/banners/${news.image}`)}
              style={{ maxwidth: "100%" }}
            />
          </div>
        </div>
        <Row>
          <Col className={"ml-auto mr-auto mt-4"} md="10">
            <h1
              className="text-900 m-0 mt-2"
              style={{
                fontSize: "2.5rem",
                color: "#fabd2f",
              }}
            >
              Marketers Must Know What To Do With The Data And Insights The Tech
              Provides
            </h1>
            <h3 className="text-4s00 m-0 mt-2">
              Interview With Alison Badenhorst
            </h3>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto mt-4"} md="10">
            <p>
              <b>
                Alison Badenhorst, Chief Marketing Officer at Rand Merchant Bank
                RMB, has over two decades of experience in marketing in
                financial services. One of the speakers at Vibe Martech Fest
                South Africa, Badenhorst says new technologies bring
                opportunities, but without a solid marketing strategy around
                them, they are just another tool in the mix.
              </b>
            </p>
            <br />
            <br />

            <p>
              <b>What are the key challenges that brands face today? </b>
            </p>

            <br />
            <p>
              Brands need to balance short term sales with long term brand
              building, which ultimately delivers business growth, both in terms
              of client base and revenue. Brands need to find ways to break
              through and capture customer ‘mental space’, given the
              proliferation of competition, messaging and channels that
              audiences are exposed to.
            </p>
            <br />
            <br />

            <p>
              <b>
                Have marketers in South Africa started adopting marketing
                technology solutions?{" "}
              </b>
            </p>
            <br />
            <p>
              Absolutely. Technology solutions have been around for some time,
              with Search, SEO and social and web channels allowing us to start
              measuring marketing impact and providing some form of ROI for
              business. These solutions have now evolved, and we are being
              inundated with new tools such as AI and Google Analytics, to name
              a few. While these new technologies present opportunities, it’s
              important that marketers obsess less about the technology and
              focus more on understanding the ‘so what’. The principles and
              strategy that guide decision making should be clear, as well as
              knowing what to do with the data and insight that the tech
              provides. In short, tech solutions are one enabler, but without a
              solid marketing strategy informing them they are just another tool
              in the mix.
            </p>
            <br />
            <br />
            <p>
              <b>How do marketers tackle customer data? </b>
            </p>
            <br />
            <p>
              Very carefully and within the legislative frameworks such as
              POPIA. As brands, trust needs to be at the heart of our
              relationship with consumers and respecting their privacy is key.
              Data does, however, become a powerful enabler of marketers and
              help businesses better understand their customers-
              particularly from a behavioural perspective, which in turn allows
              for more personalised and value adding solutions.
            </p>
            <br />
            <br />

            <p>
              <b>
                What critical information would you expect from technology
                experts?{" "}
              </b>
            </p>
            <br />
            <p>
              Tech projects often fall short when it comes to operationalisation
              and deployment. So much time and focus are spent onboarding the
              tech, and less time on rollout and adoption. Technology experts
              should be equipped to ensure the success of the tech once it’s
              landed, for example highlighting the importance of working with
              change management teams, driving usage and revisiting the tech
              once new features roll out.
            </p>
            <br />
            <br />
            <p>
              <b>How do you think Martech will evolve in the banking sector?</b>
            </p>
            <br />
            <p>
              Banks have historically been quite closed in terms of
              infrastructure. Moving forward I think we will see more use of
              APIs and customisation across ecosystems. Ensuring security and
              privacy for our customers will remain the highest priority. Data
              will become a competitive advantage when one can start using
              marketing analytics and behavioural data, together with business
              data points, to deliver richer customer insights. These insights
              will ultimately ensure improved solutions that add value for
              customers.
            </p>
            <br />
            <br />
            <p>
              <b>
                Can you share with us some technologies which you believe will
                transform marketing?{" "}
              </b>
            </p>
            <br />
            <p>
              There is a lot of buzz around AI and 5G and what that will do to
              the industry, and I’m sure going forward there will be more of
              these to come. Real transformation will, however, be achieved when
              we as marketers can pull together a single, multidimensional view
              of data points from various platforms, tech and customer
              engagement into one single view. The deeper our understanding of
              our audiences and customers, the richer our marketing solutions.
              Being able to measure true marketing impact and effectiveness will
              unlock real tangible business value.
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
