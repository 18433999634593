import React from 'react';
import { Alert} from 'reactstrap';

export default function CreateAlert({ success, error, download }) {
  const color = success ? 'success' : 'danger';
  if (!success && !error) {
    return '';
  } else {
    return (
      <Alert
        color={color}
        isOpen={true}
        fade={true}
        className="text-center"
        style={{ marginTop: '1rem' }}
      >
        {error ? (
          error
        ) : (
          <>
            Thank you. The report will download shortly.{' '}
            <a href={success} target="_blank" rel="noopener noreferrer" className="text-white">
              Click here
            </a>{' '}
            if your download does not begin.
          </>
        )}
      </Alert>
    );
  }
}
