import React from "react";

import { Container, Row, Col, Button } from "reactstrap";

function KeyNotes() {
  return (
    <>
      <div
        className="section"
        style={{
          backgroundColor: "#fff",
          marginTop: "5rem",
        }}
      >
        <Container>
          <Row>
            <Col lg={6}>
              <img
                src={require("assets/images/colin.jpg")}
                width="100%"
                className="mt-5"
                alt="colin"
              />
            </Col>
            <Col lg={6}>
              <p className="text-400 mt-5">
                Many organisations are witnessing their Customer measures
                plateau and struggle to know what to do to move their Customer
                Experience (CX) to the next level. To address these new
                problems, new thinking is needed. We need to understand the
                intuitions that drive Customer’s Behavior at an emotional,
                subconscious and psychological level.
                <br /> <br />
                <a
                  href="https://www.linkedin.com/in/ACoAAAAEV8cBixClEhzxoK1xT9c8YO2-NQk85Zg"
                  target="_blank"
                  className="text-primary text-600"
                  rel="noopener noreferrer"
                >
                  Colin Shaw
                </a>
                , Customer Experience Pioneer will be one of our speakers at CX
                NXT - Customer Experience Summit. Get a chance to win a signed
                copy of the Intuitive Customer by Colin Shaw and Ryan Hamilton.
                <br /> <br />
                Register for the event and send your questions for Colin to{" "}
                <a
                  href="https://www.linkedin.com/in/ACoAAAAEV8cBixClEhzxoK1xT9c8YO2-NQk85Zg"
                  target="_blank"
                  className="text-primary text-600"
                  rel="noopener noreferrer"
                >
                  marketing@martechvibe.com
                </a>{" "}
                .The ones we pick will receive a signed book and get mentioned
                during the session.
              </p>
              <br />
              <Button
                href="/register"
                className="btn-round navbar-item-custom text-400 px-4 mt-2"
                color="primary"
                size="lg"
                outline
              >
                <span
                  style={{
                    textTransform: "none",
                    fontWeight: 700,
                    fontSize: "16px",
                  }}
                >
                 REGISTER FOR 2024
                </span>
              </Button>{" "}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default KeyNotes;
