import React from 'react';

import Footer from 'components/Footer.js';
import ProfilePageHeader from 'components/Headers/ProfilePageHeader.js';

import { Col, Container, Row } from 'reactstrap';
import Navbar from 'components/Navbar.js';
import NavbarTop from '../components/NavbarTop.js';

function PrivacyPolicy() {
  return (
    <>
      <NavbarTop />
      <Navbar />
      <ProfilePageHeader title="Terms and Conditions" bgColor="#fff" />
      <div className="section profile-content mt-3" style={{ padding: 0 }}>
        <Container>
          <Row>
            <Col md={12}>
              <p className="text-400">
                The event CX NXT Europe is organised and managed by Vibe Projects LLC. A company
                registered in Sharjah Media City, United Arab Emirates.
              </p>
              <ol>
                <li>
                  {' '}
                  These terms and conditions set out the terms on which you agree to attend the
                  event. By registering for the event, you agree to be bound by the terms of the
                  Agreement., which constitutes the entire agreement between the parties in relation
                  to its subject matter and supersedes all previous agreements, arrangements,
                  undertakings or proposals, whether written or oral, between the parties in
                  relation to its subject matter.
                </li>
              </ol>
              <ol start="2">
                <li>
                  No amendment to, or waiver of, any provision of this Agreement shall be effective
                  unless in writing and signed by authorised representatives of both parties. No
                  waiver by either party of any breach of this Agreement by the other party shall be
                  considered to be a waiver of any subsequent breach of the same or any other breach
                  of this Agreement.
                </li>
              </ol>
              <ol start="3">
                <li>
                  {' '}
                  Nothing in this Agreement shall be construed as constituting a partnership between
                  the parties or as constituting either party as the agent of the other for any
                  purpose whatsoever, except as expressly set out in this Agreement.
                </li>
              </ol>
              <ol start="4">
                <li>
                  The parties acknowledge and agree that execution of this Agreement by electronic
                  or digital signature shall be effective execution under the law.
                </li>
              </ol>
              <ol start="5">
                <li>
                  I agree to receive further marketing information from Martechvibe/Datatechvibe on
                  our series of live and digital events. You also agree to have your contact
                  information, including email, passed on to the sponsors of this event to follow up
                  on your interests.
                </li>
              </ol>
              <p className="text-400">FORCE MAJEURE&nbsp;</p>
              <ol>
                <li>
                  Event Organizer shall not be deemed to be in breach of this Agreement or otherwise
                  liable for any failure or delay in performing our obligations under this Agreement
                  as a result of an event or series of connected events outside of our reasonable
                  control and/or the reasonable control of our sub-contractors and/or suppliers as
                  applicable (including, without limitation, strikes or other industrial disputes,
                  failure of a utility service or transport network, an act of God, war, riot, civil
                  commotion, terrorism, malicious damage, compliance with any law or governmental
                  order, rule, regulation or direction, accident, breakdown of plant or machinery,
                  fire, flood or storm).
                </li>
              </ol>
              <ol start="2">
                <li>
                  It may be necessary to alter the advertised content, timing, date and/or location
                  of the Event. We reserve the right to do this at any time during the Term and
                  without liability, provided that the Event, as altered, is similar to the Event as
                  originally advertised. The Event Organizer shall provide notice of any alterations
                  as soon as is reasonably practicable.
                </li>
              </ol>
              <ol start="3">
                <li>
                  Event Organizer reserves the right to cancel the Event at any time during the Term
                  and will notice of the same as soon as is reasonably practicable.
                </li>
              </ol>
              <ol start="4">
                <li>
                  In the event of cancellation, you shall be entitled to either: (i) credit for a
                  future event (up to the value of sums paid by you in respect of the Event); or
                  (ii) terminate this Agreement with immediate effect and obtain a refund
                  (calculated in good faith) of an amount that reflects the total sums paid by you
                  at the date of cancellation minus the value of any benefits received by you prior
                  to the date of cancellation. Any such refund shall be paid by us within 90 days of
                  receipt of confirmation from you of your wish to terminate the Agreement.
                </li>
              </ol>{' '}
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
