import React, { useContext } from "react";

import { Container, Row, Col, Card, Button } from "reactstrap";
import Slider from "react-slick";
import { DataContext } from "DataContainer";
import { groupBy } from "views/content";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: "block" }} onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} text-dark`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}
const settings = {
  dots: false,
  autoplay: true,
  autoplaySpeed: 8000,
  slidesToShow: 1,
  slidesToScroll: 1,
  className: "p-10",
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};
function Sponsor() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  const { sponsors: allSponsors } = useContext(DataContext);
  const rows = groupBy("panel")(allSponsors);
  if (Object.keys(rows).length < 1) {
    return null;
  }
  const processRows = (sRows) => {
    const sizeOfRows = Object.keys(sRows).map((r) => {
      const size = sRows[r].reduce((a, b) => a + b.size, 0);
      return { size, title: r, rows: sRows[r] };
    });

    let gridSize = 12;
    let panelNo = 0;
    let panel = [];
    sizeOfRows.map((s) => {
      console.log(s.size, gridSize, panelNo);
      gridSize -= s.size;
      if (gridSize < size) {
        gridSize = 12;
        panelNo += 1;
      }
      if (panel[panelNo]) {
        panel[panelNo] = [...panel[panelNo], s];
      } else {
        panel[panelNo] = [s];
      }
    });

    return panel;
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section pt-0"
        data-parallax={true}
      >
        <Container>
          {Object.keys(rows).map((r) => {
            const subRows = groupBy("type")(rows[r]);

            const processedRows = processRows(subRows);

            return (
              <>
                {processedRows.map((processedRow) => (
                  <>
                    <Row className="justify-content-center">
                      {processedRow.map((r) => (
                        <Col lg={r.size} xs={6} className={"ml-auto mr-auto pb-3"}>
                          <h3 className="text-400 text-dark text-center">{`${r.title}${
                            r.size > 3 ? "S" : ""
                          }`}</h3>
                        </Col>
                      ))}
                    </Row>
                    <Row className="justify-content-center">
                      {processedRow.map((r) => (
                        <>
                          {r.rows.map((s) => (
                            <Col lg={s.size} xs={6} className={"ml-auto mr-auto pb-3"}>
                              <a href={`/partner/${s.slug}`}>
                                {/* <h3 className="text-400 text-dark text-center">{s.type}</h3> */}
                                <Card className="rounded-0">
                                  <Container fluid>
                                    <Row>
                                      <Col lg={12}>
                                        <img
                                          src={s.image}
                                          alt={s.name}
                                          width="100%"
                                          className="rounded-0"
                                        />
                                      </Col>
                                    </Row>
                                  </Container>
                                </Card>
                              </a>
                            </Col>
                          ))}
                        </>
                      ))}
                    </Row>
                  </>
                ))}
              </>
            );

            // return Object.keys(processedRows).map((subRow) => (
            //   <Row>
            //     <Col lg={12}>
            //       <h3 className="text-400 text-dark text-center">{`${subRow}${
            //         subRows[subRow].length > 1 ? "S" : ""
            //       }`}</h3>
            //     </Col>
            //     {subRows[subRow].map((s) => (

            //   </Row>
            // ));
          })}
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
