import React from "react";
import { Container, Row, Col } from "reactstrap";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import { sponsorsnew } from "./content";
import NavbarTop from "../components/NavbarTop.js";

function SpeakerSingle(props) {
  const sponsorLink = props.match.params.sponsor;
  const sponsor = sponsorsnew.find((x) => x.pageLink === sponsorLink);
  console.log(sponsor);

  return (
    <>
      <NavbarTop />
      <Navbar />
      <ProfilePageHeader title={""} bgColor="#fff" />
      <div className="section profile-content mt-5">
        <Container>
          <div className="owner">
            <div
              className="avatar border"
              style={{
                backgroundColor: "#ffffff",
                maxWidth: "100%",
              }}
            >
              <a href={sponsor.link} target={"_blank"}>
                <img
                  alt={sponsor.name}
                  className="img-rounded img-no-padding img-responsive"
                  src={require(`assets/sponsors/${sponsor.image}`)}
                  style={{ maxWidth: "400px", width: "100%" }}
                />
              </a>
            </div>
            <div className="name">
              <h4 className="title" style={{ marginBottom: "0" }}>
                {sponsor.name}
              </h4>
              <br />
            </div>
          </div>
          <Row>
            <Col className="" md="12">
              {sponsor.text.map((t, index) => (
                <p key={t} dangerouslySetInnerHTML={{ __html: t }}></p>
              ))}
            </Col>
          </Row>
          <Row>
            <Col className="" md="12">
              <p>
                {" "}
                Learn More: <a href={sponsor.link}>{sponsor.link}</a>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default SpeakerSingle;
