import React from "react";

import Footer from "components/Footer.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import SponsorAll from "components/SponsorAll.js";
import Navbar from "components/Navbar.js";


function PrivacyPolicy() {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title="2023 Sponsors & Partners" bgColor="#fff" />
      <SponsorAll />

      <Footer />
    </>
  );
}

export default PrivacyPolicy;
