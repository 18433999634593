import React from "react";

import { Container, Row, Col, Card, Button } from "reactstrap";

function Sponsor({ sponsors }) {
  // const rows = [["PRESENTING SPONSOR"], ["DIGITAL EXPERIENCE PARTNER"], ["GOLD SPONSOR"]];
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
        }}
        className="section pb-0"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h2 className="header-text text-uppercase text-center text-primary">
                PAST EDITION SPONSORS
              </h2>
            </Col>
          </Row>

          <Row>
            {sponsors.map((s) => (
              <Col lg={3} xs={6} className={"ml-auto mr-auto pb-3"}>
                <a href={`/sponsors/${s.pageLink}`}>
                  <Card style={{ borderRadius: size ? "20px" : "10px" }}>
                    <Container fluid>
                      <Row>
                        <Col lg={12}>
                          <img
                            src={require(`assets/sponsors/${s.image}`)}
                            alt={s.name}
                            width="100%"
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Card>
                </a>
              </Col>
            ))}
          </Row>

          {/* <Row>
            <Col lg={12} className={"text-center"}>
              <Button
                href="/sponsor-cxnxt"
                className="navbar-item-custom text-400 text-white px-4 rounded-0 mb-3"
                color="danger"
                size="lg"
              >
                <span
                  style={{
                    color: "#fff",
                    textTransform: "none",
                    fontWeight: 700,
                    fontSize: "16px",
                  }}
                  className="header-text"
                >
                  SPONSOR GLOBAL EDITIONS
                </span>
              </Button>
            </Col>
          </Row> */}
        </Container>
        {/* <Container>
          <Row>
            <Col lg={12}>
              <h2 className="header-text text-center mb-5" style={{ color: "#0a2b7c" }}>
                2022 SPONSORS & PARTNERS
              </h2>
            </Col>
          </Row>
          {rows.map((row) => (
            <Row>
              {row.map((sponsor) =>
                sponsors[sponsor].map((s) => (
                  <Col lg={s.grid || 4} xs={s.smallGrid || 6} className={"ml-auto mr-auto pb-3"}>
                    <a href={`/sponsors/${s.pageLink}`}>
                      <p
                        className="text-muted text-center text-400 mb-2"
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: !size && "12px",
                        }}
                      >
                        {s.type}
                      </p>
                      <Card style={{ boxShadow: "none" }} className="rounded-0">
                        <Container fluid>
                          <Row>
                            <Col lg={12}>
                              <img
                                src={require(`assets/sponsors/${s.image}`)}
                                alt={s.name}
                                width="100%"
                              />
                            </Col>
                          </Row>
                        </Container>
                      </Card>
                    </a>
                  </Col>
                ))
              )}
            </Row>
          ))}
        </Container> */}
      </div>
    </>
  );
}

export default Sponsor;
