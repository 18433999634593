import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

import Footer from "components/Footer.js";
import { Container, Row, Col } from "reactstrap";
import NavbarTop from "../components/NavbarTop.js";

function ThankYou({
  match: {
    params: { id },
  },
}) {
  return (
    <>
      <NavbarTop />
      <Navbar />
      <ProfilePageHeader title={`THANK YOU `} bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0, background: "#eee" }}>
        <Container>
          <Row>
            <Col lg={12} className="text-center text-dark p-5 m-5">
              <h3>
                {id === "REGISTER" && (
                  <>
                    Thank you for your interest to join Velocity UAE!
                    <br />
                    <br />{" "}
                    <p className="text-400 mt-3">
                      Please note: Filling out the form does not guarantee admission to the event.
                      As a standard protocol, the Organising Team will evaluate the registration
                      based on the attendee eligibility criteria. The Organising Team will get in
                      touch with you for further details should your registration be accepted.{" "}
                    </p>
                  </>
                )}
                {id === "BROCHURE" && (
                  <>
                    Thank you for your interest in the Velocity UAE. A member of our team
                    will be in touch with you for further details about the event.
                  </>
                )}
                {id === "AGENDA" && (
                  <>
                    Thank you for your interest in the Velocity UAE. A member of our team
                    will be in touch with you for further details about the event.
                  </>
                )}
                {id === "SPONSOR" && (
                  <>
                    Thank you for your interest in the Velocity UAE. A member of our team
                    will be in touch with you for further details about the event.
                  </>
                )}
                {id === "AWARD" && (
                  <>
                    Thank you for your interest in the Velocity UAE. A member of our team
                    will be in touch with you for further details about the awards.
                  </>
                )}
                {id === "REPORT" && (
                  <>
                    Thank you for your interest in the Velocity UAE. A member of our team
                    will be in touch with you for further details about the event.
                  </>
                )}
              </h3>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <Sponsor sponsors={sponsorsPage} /> */}
      <Footer />
    </>
  );
}

export default ThankYou;
