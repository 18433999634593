import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

import Footer from "components/Footer.js";
import NavbarTop from "components/NavbarTop.js";
import PriceCard from "components/PriceCard.js";

function PaymentRedirect({ location }) {
  const searchParams = new URLSearchParams(location.search);
  const lead = searchParams.get("lead");
  return (
    <>
      <NavbarTop />
      <Navbar />
      <ProfilePageHeader title={`PRICING OPTIONS`} bgColor="#fff" />
      {/* <SaleHeader title={`PRICING OPTIONS`} bgColor="#fff" /> */}
      <PriceCard lead={lead} />

      <Footer />
    </>
  );
}

export default PaymentRedirect;
