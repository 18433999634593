import React from "react";

import { Container, Row, Col, Button } from "reactstrap";

const content = [
  {
    id: 1,
    title: "DESIGNATIONS",
    values: [
      "Chief Data Officers",
      "Chief Data & Analytics Officers",
      "Chief Technology Officers",
      "Chief Information Officers",
      "Chief Digital Officers",
      "Chief Information Security Officers",
      "Vice-Presidents/Directors/General Managers/Heads – Data, Data Science, Analytics, Business Intelligence, Data Governance, Data Management, Advanced Analytics, Data Transformation, Technology",
      "Managers/Leads/Analysts/Specialists – Data, Data Science, Analytics, Business Intelligence, Data Governance, Advanced Analytics, Data Transformation, Information Technology, Technology, Digital Transformation",
    ],
    color: "#cbfcf6",
  },
  {
    id: 2,
    title: "FOCUS INDUSTRIES",
    values: [
      "BFSI",
      "Government",
      "Retail & eCommerce",
      "Telecom",
      "Oil & Gas",
      "Healthcare",
      "Education",
      "Fintech",
      "Travel & Hospitality",
      "Real Estate",
      "FMCG",
    ],
    color: "#280378",
  },
];

function KeyNotes() {
  return (
    <>
      <div
        className="section"
        style={{
          backgroundColor: "transparent",
        }}
      >
        <Container>
          <Row>
            <Col lg="12">
              <h2 className="header-text m-0 my-4 text-primary text-center">
                WHO IS THIS MEANT FOR ?
              </h2>
            </Col>
          </Row>

          <Row className="justify-content-center">
            {content.map((data, index) => (
              <Col
                md={5}
                xs={12}
                key={index}
                className="px-1 "
                style={{ backgroundColor: index === 0 ? "#cbfcf6" : "#280378" }}
              >
                <div className="text-white p-3">
                  <h4
                    className="text-left text-700 ml-3"
                    style={{ color: index === 0 ? "#000" : "#fff" }}
                  >
                    {data.title}
                  </h4>
                  <ul style={{ color: index === 0 ? "#000" : "#fff" }}>
                    {data.values.map((c) => (
                      <li className=" text-left pt-2" key={c}>
                        <p className="m-0  text-400">{c}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default KeyNotes;
