import React from "react";

import { Container, Row, Col, Card } from "reactstrap";

function News({ news }) {
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <Row>
            {news.map((n) => (
              <Col lg={4} className={"ml-auto mr-auto"}>
                <Card style={{ minHeight: "280px", boxShadow: "none" }} className="rounded-0">
                  <img src={n.image} alt="meltwater" className="rounded-0" width="100%" />
                  <div className="p-3">
                    <p className="text-dark text-400 m-0">{n.title}</p>
                  </div>
                  <a
                    href={n.link}
                    target="_blank"
                    without
                    rel="noopener noreferrer"
                    className="text-danger header-text px-3"
                  >
                    Read More ...
                  </a>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default News;
