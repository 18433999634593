import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import Register from "components/Register";
import Footer from "components/Footer.js";
import Resource from "components/Resource.js";
// import SponsorNew from "components/SponsorNew.js";
// import { sponsorsPage } from "./content";
import NavbarTop from "../components/NavbarTop.js";

function RegisterPage({ type, title, subject }) {
  return (
    <>
      <NavbarTop />
      <Navbar />
      <ProfilePageHeader title={title} bgColor="#fff" />
      <Resource />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Register type={type} subject={subject} />
      </div>

      {/* <SponsorNew sponsors={sponsorsPage} /> */}
      <Footer />
    </>
  );
}

export default RegisterPage;
