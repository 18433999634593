import React from "react";

import { Container, Row, Col } from "reactstrap";
// import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
function Testimonials() {
  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };

  return (
    <>
      <div
        style={{
          background: "#2b007b",
        }}
        className="section"
      >
        <Container>
          <Row></Row>
          <Row className="justify-content-center">
            <Col lg={3}>
              <div className="text-center">
                <h2 className={`text-900 text-white`}>Testimonials</h2>
                <p className={`text-400 text-white mb-4 `}>
                  Here's what our participants have to say about <b>VELOCITY, DUBAI</b>
                </p>
              </div>
            </Col>
            <Col lg={1} />
            <Col lg={8} xs={10} className="text-center">
              <Slider {...settings}>
                {content.map((c) => (
                  <>
                    <h4
                      className="text-white text-400 px-3"
                      dangerouslySetInnerHTML={{ __html: c.testimonial }}
                    />
                    <br />
                    <h5
                      className="text-white text-400"
                      style={{ fontWeight: 700 }}
                      dangerouslySetInnerHTML={{ __html: c.by }}
                    />
                  </>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  {
    testimonial:
      "It has been very valuable for us. This human factor is really priceless so we really really enjoyed that. ",
    by: "Hafdi Salah, Co-founder and Managing Partner – BBI Consultancy",
  },

  {
    testimonial:
      "It’s been an amazingly well organized and most outstanding aspect has been able to see customers face to face again and get that intimate touch to hear about the problems and challenges.",
    by: "Brendan Moran, Regional VP EMEA, Solutions Engineering – Cloudera",
  },

  {
    testimonial:
      "Everything is at the very high level. The audience is perfect. We had really good contacts here, the audiences of high level and right people. ",
    by: "Alexey Sidorov, Data Management Director & Chief Evangelist, Middle East – Denodo",
  },

  {
    testimonial:
      "Live, face to face meetings and interactions with customers and easy to find the location are what I liked about the event.",
    by: "Shahan Ur Rehman, Sr Business Development Manager, BBI Consultancy LLC",
  },
];

export default Testimonials;
