import React from 'react';
import { Container, Button, Row, Col, Card, CardBody } from 'reactstrap';
import Countdown from 'react-countdown';

const renderer = ({ days, hours, minutes, completed }) => {
  if (completed) {
    return 'SALE ENDED';
  }
  return <>{days} Days</>;
};

function Agenda({lead}) {
  return (
    <>
      <div
        style={{
          backgroundColor: '#efefef',
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-center">
            {content.map((c) => (
              <Col md={6}>
                <Card style={{ backgroundColor: '#092e82' }}>
                  <CardBody className="text-white">
                    <div className={'text-center d-flex m-auto'}>
                      <h1 className="text-700 mr-3">€{c.saleprice}</h1>
                      <h3 className=" text-strikethrough align-self-center">
                        <del>€{c.actualPrice}</del>
                      </h3>
                    </div>
                    <div>
                      <h4 className="text-500 mt-0">Save €{c.saving}</h4>
                    </div>
                    <div className="mb-3">
                      <h3 className="text-700 mt-0">{c.title}</h3>
                      <p className="text-700 mt-0">{c.description}</p>
                    </div>
                    <Button
                      href={`/payment?option=${c.type}&lead=${lead}`}
                      className="btn mb-4"
                      color="danger"
                      size="lg"
                    >
                      <span
                        style={{
                          color: '#fff',
                          textTransform: 'none',
                          fontWeight: 700,
                          fontSize: '16px',
                        }}
                        className="header-text"
                      >
                        {' '}
                        Book Now
                      </span>
                    </Button>
                    <hr style={{ border: 0, borderTop: '5px solid #09d895' }} />
                    <div className="mt-4" style={{ color: '#fff' }}>
                      {c.bulletPoints}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
            <Col lg={12}>
              {/* <Card style={{ backgroundColor: '#092e82' }}>
                <CardBody className="text-white"> */}
                  <p className="text-700 mt-0 text-danger">
                    *Important note: This event is exclusive only for delegates from the region.
                    Solution providers and consultants will not be accepted. The organising
                    committee has the right to reject your registration if you are not deemed fit
                    for the event and shall deduct 15% service charge upon cancellation and refunding of
                    your payment.
                  </p>
                {/* </CardBody>
              </Card> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  {
    title: 'Pre-Early bird - Individual Delegate Pass',
    description: (
      <span>
        {/* Sale ends in <Countdown date={new Date(1652444924000)} renderer={renderer} /> */}
      </span>
    ),
    type: 'delegate',
    saleprice: '499',
    actualPrice: '799',
    saving: '300',
    bulletPoints: (
      <ul>
        <li>Two Full-Day Access Pass to the Conference</li>
        <li>Access to Post Event Speaker Presentations </li>
        <li>Complimentary 1 year Membership to the World Martech Council </li>
        <li>Access to Networking Session</li>
        <li>Lunch & Refreshments</li>
      </ul>
    ),
  },
  {
    title: 'Group Booking (4x Delegates)',
    description: (
      <span>
        {/* Sale ends in <Countdown date={new Date(1652444924000)} renderer={renderer} /> */}
      </span>
    ),
    type: 'group',
    saleprice: '1799',
    actualPrice: '3196',
    saving: '1966',
    bulletPoints: (
      <ul>
        <li>Two Full-Day Access Pass to the Conference for Up to 4 Delegates </li>
        <li>Access to Post Event Speaker Presentations </li>
        <li>Complimentary 1 year Membership to the World Martech Council </li>
        <li>Access to Networking Session</li>
        <li>Lunch & Refreshments</li>
      </ul>
    ),
  },
];
export default Agenda;
