import React from "react";

import { Container, Row, Col, Card, Button } from "reactstrap";

function News({ news }) {
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col xs={12} className="mb-5">
              <h2 className="text-900 text-center" style={{ color: "#0a2b7c" }}>
                NEWS
              </h2>
            </Col>
          </Row>
          <Row>
            {news.map((n) => (
              <Col lg={3} className={"ml-auto mr-auto"}>
                <Card style={{ minHeight: "280px", boxShadow: "none" }} className="rounded-0">
                  <img src={n.image} alt="meltwater" className="rounded-0" width="100%" />
                  <div className="p-3">
                    <p className="text-dark text-400 m-0">{n.title}</p>
                  </div>

                  <Button
                    href={n.link}
                    className="rounded-0 text-700 px-4 border-0"
                    style={{
                      background: "#2b007b",
                    }}
                    size="md"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span
                      style={{
                        fontSize: "15px",
                      }}
                    >
                      Read More ...
                    </span>
                  </Button>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default News;
