import React from "react";
import Navbar from "components/Navbar.js";
import NavbarTop from "../components/NavbarTop.js";

import Footer from "components/Footer.js";
import Colin from "components/Colin.js";
// import SponsorNew from "components/SponsorNew.js";
// import { sponsorsPage } from "./content";

function RegisterPage() {
  return (
    <>
      <NavbarTop />
      <Navbar />

      <Colin />
      {/* <SponsorNew sponsors={sponsorsPage} /> */}
      <Footer />
    </>
  );
}

export default RegisterPage;
