import React from "react";
import Slider from "react-slick";

import { Container, Row, Col, Card } from "reactstrap";

function SpeakerGrid({ speakers }) {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });

  function SpeakerCard({ speaker }) {
    return (
      <Card
        className="card-background-image mx-auto p-3"
        style={{
          borderRadius: 0,
          boxShadow: "none",
          background: "transparent",
        }}
      >
        <Container fluid>
          <Row>
            <Col xs={12} className="px-0">
              <img
                src={require(`assets/speakers/${speaker.image}`)}
                alt="speaker"
                width="100%"
                className="img-circle"
                style={{ borderRadius: 0 }}
              />
            </Col>

            <Col xs={12} className="px-0">
              <div className="text-center">
                <p
                  className="text-700 text-uppercase mt-2 mb-0 d-block px-0"
                  style={{ fontSize: "14px" }}
                >
                  {speaker.name}
                </p>
                <p className="text-300 m-0" style={{ fontSize: "14px" }}>
                  {speaker.title}
                </p>
                <p className="text-400 m-0 d-block px-0" style={{ fontSize: "14px" }}>
                  {speaker.company}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Card>
    );
  }
  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section"
        data-parallax={true}
      >
        <div className="filter" />
        <Col lg="12">
          <h2 className="header-text m-0 my-4 text-primary text-center">PAST EDITION SPEAKERS</h2>
        </Col>
        <Container>
          <Row>
            {size ? (
              speakers.map((speaker, index) => (
                <Col className="mx-auto px-0" lg={3} md={6} key={index}>
                  <SpeakerCard speaker={speaker} />
                </Col>
              ))
            ) : (
              <Col xs={11} className={"mx-auto"}>
                <Slider {...settings}>
                  {speakers.map((speaker, index) => (
                    <SpeakerCard speaker={speaker} />
                  ))}
                </Slider>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SpeakerGrid;
