import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import Register from "components/Register";
import Footer from "components/Footer.js";

import NavbarTop from "../components/NavbarTop.js";
import SpeakerGrid from "components/SpeakerCardRegister";
import { Col, Container, Row } from "reactstrap";
import Slider from "react-slick";
const settings = {
  // dots: true,
  autoplay: true,
  arrows: false,
  autoplaySpeed: 1000,
  slidesToShow: 8,
  slidesToScroll: 1,
  className: "p-10",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
  ],
};
function RegisterPage({ type, title, subject }) {
  return (
    <>
      <NavbarTop />
      <Navbar />
      <ProfilePageHeader title={title} bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Register type={type} subject={subject} />
      </div>
      {type === "REGISTER" && <SpeakerGrid />}
      {/* <ProfilePageHeader
        title={
          type === "SPONSOR"
            ? "BE A PART OF OUR 2023 SPONSORS & PARTNERS LIST"
            : "2022 SPONSORS & PARTNERS"
        }
        bgColor="#fff"
        noPadding
      />
      <SponsorAll sponsors={sponsorsPage} /> */}
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="fixed-bottom d-flex justify-content-center"
        data-parallax={true}
      >
        <Container fluid>
          <Row>
            <Col lg={12} xs={12} className="mb-1">
              <h4 className="text-400 text-dark my-1 my-0 text-center text-uppercase">
                Brands joining Velocity UAE
              </h4>
              <Slider {...settings}>
                {logos.map((c) => (
                  <div className="px-3">
                    <img
                      src={require(`assets/joining/${c}`)}
                      key={c}
                      alt={c}
                      style={{
                        // maxWidth: "100%",
                        maxHeight: "50px",
                        // alignSelf:"center"
                      }}
                    />
                  </div>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

const logos = [
  "Abu Dhabi Digital Authority (ADDA)_.jpg",
  "Abu Dhabi Health Services Company (SEHA)_.jpg",
  "Al-Futtaim_.jpg",
  "Aramex.jpg",
  "Batelco_.jpg",
  "Cigna.jpg",
  "Decidable.jpg",
  "Dodo Brands_.jpg",
  "Dubai Holding 1.jpg",
  // "Dubai Holding_.jpg",
  "Emirates 1.jpg",
  // "Emirates.jpg",
  "Expo 2020 Dubai_-1.jpg",
  // "Expo 2020 Dubai_.jpg",
  "First Abu Dhabi Bank 1.jpg",
  "First National Bank South Africa_.jpg",
  "Gulf Bank 1.jpg",
  // "Gulf Bank_.jpg",
  "Mars.jpg",
  "Mcdonalds 1.jpg",
  // "Mcdonalds.jpg",
  "Miral Experiences_.jpg",
  "Outra.jpg",
  "Pearson.jpg",
  "Tamara.jpg",
  "Ternary Data.jpg",
  "_CIB Egypt_.jpg",
  "_HSBC.jpg",
];

export default RegisterPage;
