import React from "react";
import {
  Container,
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import AnchorLink from "react-anchor-link-smooth-scroll";

function NavbarDiv({ home }) {
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [scroll, setScroll] = React.useState(home ? true : false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
        setScroll(false);
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
        setScroll(true);
      }
      if (!home) {
        setScroll(false);
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      <Navbar
        style={{ height: "50px", backgroundColor: "#fff" }}
        color-on-scroll="300"
        expand="lg"
        className={"fixed-top"}
      >
        <Nav className="ml-auto m-0"></Nav>
        <Nav className="ml-auto m-0" navbar></Nav>
        <div className="d-flex align-self-center">
          <a href="/sponsor" className="text-dark mr-3">
            <span
              style={{
                textTransform: "none",
                fontWeight: 700,
                fontSize: "14px",
              }}
            >
              BECOME A SPONSOR
            </span>
          </a>
          <a href="/register" className="text-dark mr-3">
            <span
              style={{
                textTransform: "none",
                fontWeight: 700,
                fontSize: "14px",
              }}
            >
              REGISTER FOR 2024
            </span>
          </a>
          {/* <Button
            href="/register"
            className="rounded-0 navbar-item-custom text-400 px-4 mr-5 ml-0 border-none"
            style={{ backgroundColor: "#fff", border: "none", margin: "0 3px" }}
            size="lg"
          >
            <span
              style={{
                textTransform: "none",
                fontWeight: 700,
                fontSize: "16px",
              }}
            >
              REGISTER
            </span>
          </Button> */}
        </div>
      </Navbar>
      <Navbar
        className={`fixed-top`}
        style={{ minHeight: "50px", marginTop: size ? "48px" : "42px", backgroundColor: "#2b007b" }}
        color-on-scroll="300"
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-translate">
            <NavbarBrand data-placement="bottom" to="/" className="p-0" tag={Link}>
              <img
                src={require(`assets/images/logo/Velocity2.png`)}
                width="100%"
                style={{ maxWidth: size ? "200px" : "250px" }}
                alt="logo"
              />
            </NavbarBrand>
            <button
              aria-expanded={navbarCollapse}
              className={classnames("navbar-toggler text-right navbar-toggler", {
                toggled: navbarCollapse,
              })}
              onClick={toggleNavbarCollapse}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse className="justify-content-end" navbar isOpen={navbarCollapse}>
            <Nav navbar>
              {menu.map((link, i) =>
                !link.items ? (
                  <NavItem key={i}>
                    {window.location.pathname === "/" && link.page === "/" ? (
                      <AnchorLink
                        offset="100"
                        href={`#${link.link}`}
                        onClick={toggleNavbarCollapse}
                        className="nav-link text-400"
                        style={{ color: scroll ? "#fff" : "#fff" }}
                      >
                        {link.name}
                      </AnchorLink>
                    ) : link.page ? (
                      <a
                        href={`/#${link.link}`}
                        className="nav-link text-400"
                        style={{ color: scroll ? "#fff" : "#fff" }}
                      >
                        {link.name}
                      </a>
                    ) : (
                      <a
                        href={`${link.link}`}
                        className="nav-link text-400"
                        style={{ color: scroll ? "#fff" : "#fff" }}
                      >
                        {link.name}
                      </a>
                    )}
                  </NavItem>
                ) : (
                  <UncontrolledDropdown nav inNavbar key={i}>
                    <DropdownToggle
                      nav
                      caret
                      className=" text-400"
                      style={{ color: scroll ? "#fff" : "#fff" }}
                    >
                      {link.name}
                    </DropdownToggle>
                    <DropdownMenu right>
                      {link.items.map((i) => (
                        <DropdownItem
                          href={i.link}
                          key={i.link}
                          className=" text-400"
                          onClick={toggleNavbarCollapse}
                        >
                          {i.name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )
              )}

              <NavItem className="d-flex align-middle">
                {social.map((s) => (
                  <a
                    key={s.name}
                    className={`btn-primary text-400 px-2`}
                    style={{
                      color: scroll ? "#fff" : "#fff",
                      backgroundColor: "transparent",
                      paddingTop: "20px",
                    }}
                    href={s.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={toggleNavbarCollapse}
                  >
                    <i
                      className={`fa fa-${s.name}`}
                      style={{
                        fontSize: "1.5rem",
                      }}
                    />
                  </a>
                ))}
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarDiv;

const menu = [
  {
    name: "HOME",
    link: "/",
    page: undefined,
    items: undefined,
    grid: 1,
  },
  // {
  //   name: "About",
  //   link: "about",
  //   page: "/",
  //   items: undefined,
  //   grid: 1,
  // },
  {
    name: "ABOUT",
    link: "/about",
    page: undefined,
    items: [
      {
        name: "ABOUT THE EVENT",
        link: "/about",
        grid: 1,
      },
      {
        name: "WATCH ON DEMAND",
        link: "/on-demand",
        grid: 1,
      },
    ],
    grid: 1,
  },

  // {
  //   name: "Agenda",
  //   link: "agenda",
  //   page: "/",
  //   items: undefined,
  //   grid: 1,
  // },
  {
    name: "SPEAKERS",
    link: "/speakers",
    page: undefined,
    items: [
      {
        name: "2023 Speakers",
        link: "/speakers",
        grid: 1,
      },
      {
        name: "Past Speakers",
        link: "/past-speakers",
        grid: 1,
      },
    ],
    grid: 1,
  },
  {
    name: "PARTNERS",
    link: "/partners",
    page: undefined,
    items: [
      {
        name: "2023 Sponsors & Partners",
        link: "/partners",
        grid: 1,
      },
      {
        name: "Past Sponsors",
        link: "/past-sponsors",
        grid: 1,
      },
    ],
    grid: 1,
  },
  // {
  //   name: "Partners",
  //   link: "partners",
  //   page: "/",
  //   items: undefined,
  //   grid: 1,
  // },
  // {
  //   name: "On Demand",
  //   link: "on-demand",
  //   page: undefined,
  //   items: undefined,
  //   grid: 1,
  // },
  // {
  //   name: "NEWS",
  //   link: "media",
  //   page: "/",
  //   items: undefined,
  //   grid: 1,
  // },
  {
    name: "SPEAKER INSIGHTS",
    link: "/speaker-insights",
    page: undefined,
    items: undefined,
    grid: 1,
  },
  {
    name: "GLOBAL EDITIONS",
    link: "/",
    page: undefined,
    items: [
      {
        name: "Velocity South Africa",
        link: "https://za.velocityda.com",
        grid: 1,
      },
      {
        name: "Velocity Saudi Arabia",
        link: "https://ksa.velocityda.com",
        grid: 1,
      },
    ],
    grid: 1,
  },
  // {
];

const social = [
  // {
  //   name: "facebook",
  //   link: "https://www.facebook.com/martechvibe/",
  // },
  {
    name: "twitter",
    link: "https://twitter.com/datatechvibe?lang=en ",
  },
  {
    name: "linkedin",
    link: "https://www.linkedin.com/showcase/13758513/admin/",
  },
  // {
  //   name: "instagram",
  //   link: "https://www.instagram.com/martechvibe",
  // },
];
